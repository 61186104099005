
  <div class="modal-header">
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
        </button>
  </div>

  <app-confirmandsubmit [confirmData]="confirmData" (submitClicked)="submitClicked()"></app-confirmandsubmit>

  <app-machinedata [machineData]="machineData"></app-machinedata>

  <app-sectiondata *ngFor="let section of sectionData" [sectionData]="section"></app-sectiondata>

  <div *ngIf="physicalData !=null">
    <!--<app-physicaldata *ngIf="physicalData !=null" [physicalData]="physicalData"></app-physicaldata>-->
    <app-physicaldata *ngFor="let section of physicalData" [physicalData]="section.data" [sectionHeader]="section.sectionName"></app-physicaldata>
  </div>

  <app-confirmandsubmit (submitClicked)="submitClicked()"></app-confirmandsubmit>