import { Injectable } from '@angular/core';
import { Observable ,  Subscription ,  ReplaySubject ,  Subject } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthorizedService} from '../authorized/authorized.service';
import { Profstudy } from '../../models/profstudy';

@Injectable()
export class ProfstudyService {

	loginSubscription: Subscription;
  public profstudySubject = new ReplaySubject<Profstudy[]>();
  public roundStatus = [];
  public roundSubject = new ReplaySubject<any[]>();
  public roundIdSubject = new ReplaySubject<String>();
  public myProfstudiesList = [];

  //variables used by profstudydetails
  public found : boolean;
  public studies;
  public selectedProfstudies;
  public studyTypes;
  public detailsRoundStatus;
  public activeRound: string;

  constructor(private authorizedService:AuthorizedService, private http:HttpClient) {

      var request = this.http.get('/refcig-services/rounds');
      request.subscribe((res) => this.recievedRounds(res));
      this.authorizedService.profstudiesSubject.subscribe((res)=>this.recievedProfstudies(res));

  }

  getMyProfstudies(round:String){

    for(var i=0; i<this.myProfstudiesList.length; i++){
      if(this.myProfstudiesList[i].roundName==round){
        return this.myProfstudiesList[i];
      }
    }
    return [];
  }
recievedProfstudies(res){
  this.myProfstudiesList = [];
  for(var i=0; i<res.length; i++){
  	var roundName = res[i].round.profStudyReferenceId;
    if(this.myProfstudiesList[this.myProfstudiesList.length-1] == null || this.myProfstudiesList[this.myProfstudiesList.length-1].roundName != roundName){
      this.myProfstudiesList.push({"roundName":roundName,"purchasedStudies":new Array()});
    }
	this.myProfstudiesList[this.myProfstudiesList.length-1].purchasedStudies.push(res[i]);
  }
  this.myProfstudiesList.sort(function comparefn(e1,e2){
    var round1 = e1.roundName.split("-")[1];
    var round2 = e2.roundName.split("-")[1];
    if(round1 < round2){
      return 1;
    }
    return 0;
  });
  //this.activeRound = this.myProfstudiesList[this.myProfstudiesList.length -1].roundName;
  this.profstudySubject.next(this.myProfstudiesList);
}

calculateRoundStatus(round){
  
 // debugger;
  /*
  var today = Date.now();
  var is_safari = navigator.userAgent.indexOf("Safari") > -1;
  var is_ie11 = navigator.userAgent.includes("Trident/7.0");
  var submitPortalStartDate = new Date(round.submitPortalStartDate);
  var endDate = new Date(round.endDate);
  var commentsEndDate = new Date(round.commentsEndDate);

  if(is_ie11){
    submitPortalStartDate = new Date(round.submitPortalStartDate.split(".")[0]);
    endDate = new Date(round.endDate.split(".")[0]);
    commentsEndDate = new Date(round.commentsEndDate.split(".")[0]);
  }

  if(is_safari){
    var dateString = round.submitPortalStartDate.split(/[-T.]/);
    submitPortalStartDate = new Date(dateString.slice(0,3).join('/') + ' ' + dateString[3]);
    dateString = round.endDate.split(/[-T.]/);
    endDate = new Date(dateString.slice(0,3).join('/') + ' ' + dateString[3]);
    dateString = round.commentsEndDate.split(/[-T.]/);
    commentsEndDate = new Date(dateString.slice(0,3).join('/') + ' ' + dateString[3]);
  }


//debugger;
    if(today < submitPortalStartDate.valueOf()){//Round for sale but not open for data submission yet
      var daysLeft = Math.floor((((((submitPortalStartDate.valueOf() - today)/1000)/60)/60)/24));
      if(daysLeft == 0){daysLeft += 1;}
      return "Submission Portal Opens on " + (submitPortalStartDate.getMonth()+1) +"/"+submitPortalStartDate.getDate()+"/"+submitPortalStartDate.getFullYear() +" - " + daysLeft + " day(s)";
    }
    else{//round for sale and submit portal is open
      if(today < endDate.valueOf()){//submit portal is still open
        var daysLeft = Math.floor((((((endDate.valueOf() - today)/1000)/60)/60)/24));
        if(daysLeft == 0){daysLeft += 1;}
        return "Submission Portal Open Until " + (endDate.getMonth()+1) +"/"+endDate.getDate()+'/'+endDate.getFullYear() +" - " + daysLeft + " day(s)";
      }
      else{//submit portal closed
        if(round.interimReport == null){//waiting on interim report
          this.roundStatus.push({"name":round.profStudyReferenceId,"status":"Waiting on Interim Report"});
          return "Waiting on Interim Report";
        }
        else{// interim report available 
          if(today < Date.parse(round.commentsEndDate)){//interim report available for comments
            var daysLeft = Math.floor((((((commentsEndDate.valueOf() - today)/1000)/60)/60)/24));
            if(daysLeft == 0){daysLeft += 1;}
            return   "Interim Report Available! Please submit any comments by " + (commentsEndDate.getMonth()+1) +"/"+commentsEndDate.getDate()+'/'+commentsEndDate.getFullYear() +" - " + daysLeft + " day(s)";
          }
          else{//comments closed, waiting on final report
            if(round.report != null){//final report available
              return "Final Report Available";
            }
            else{
              return "Waiting on Final Report";
            }
          }
          }
        }  
      }*/
}

recievedRounds(res){
  //got the rounds from the server calculate the round status
  this.roundSubject.next(res);
  }

setUpProfstudyDetails(roundId){
  //pass the round id ie CIG-2021A to the profstudy component so that the correct round can be highlighted in the 
  //side navigation bar
  this.roundIdSubject.next(roundId);
  this.found = false;
  var i = 0;
  this.studies = new Object();
  this.studyTypes = [];
  //first find the selected round

  while(!this.found && i < this.myProfstudiesList.length){
    if(this.myProfstudiesList[i].roundName == roundId){
      this.selectedProfstudies = this.myProfstudiesList[i];
      //this.detailsRoundStatus = this.calculateRoundStatus(this.selectedProfstudies.purchasedStudies[0].round);
      debugger;
      this.detailsRoundStatus = this.selectedProfstudies.purchasedStudies[0].round.statusText;
      this.found = true;
    }
    i++;
  }     
  if(this.found){
    for(var i=0; i< this.selectedProfstudies.purchasedStudies.length; i++){
      if(this.studies[this.selectedProfstudies.purchasedStudies[i].round.machArch] == null){
        //add study type and create array
        this.studyTypes.push(this.selectedProfstudies.purchasedStudies[i].round.machArch);
        this.studies[this.selectedProfstudies.purchasedStudies[i].round.machArch] = new Array<any>();
      }
      this.studies[this.selectedProfstudies.purchasedStudies[i].round.machArch].push(
        {"id":this.selectedProfstudies.purchasedStudies[i].id,
      "status":this.selectedProfstudies.purchasedStudies[i].status, 
      "purchaser":this.selectedProfstudies.purchasedStudies[i].username})
    }
  }
}

}   