import { Component, OnInit } from '@angular/core';
import {News} from '../../models/news';
import { NewsService } from '../../services/news/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})

export class NewsComponent implements OnInit {
  closure:Boolean = false;
  news: Array<News>;
  constructor(private newsService: NewsService) {this.news = new Array<News>(); }
  ngOnInit() {
    this.newsService.getNews().subscribe(news => this.setNews(news));
  }
  setNews(news){
    this.news = news;
    for(var i=0;i<news.length;i++){
      var lowercase = news[i].message.toLowerCase();
      if(lowercase.includes("closing") || lowercase.includes("closed")){
        this.closure = true;
      }
    }
  }
}
