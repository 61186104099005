import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ProfstudyService } from '../../../../services/profstudy/profstudy.service';

@Component({
  selector: 'app-confirmandsubmit',
  templateUrl: './confirmandsubmit.component.html',
  styleUrls: ['./confirmandsubmit.component.css']
})
export class ConfirmandsubmitComponent implements OnInit {

  @Input() confirmData : any;
  @Output() submitClicked  = new EventEmitter<boolean>();
  constructor() {
  }
  ngOnInit() {
  }

  submitData(event){
    event.target.disabled = true;
    this.submitClicked.emit(true);
  }
}
 