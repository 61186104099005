import { Offering } from "./offering"

export class Product {

    "id": number;
    "name": string;
    "model": string;
    "description": string;
    "image": string;
    "code": string;
    "imageLarge": string;
    "category": string;
    "displayOrder": number;
    "offerings": Offering[];

    "startDate": string;
    "endDate": string;
   
    constructor(json:any) {
        this.id = json.id;
        this.name = json.name;
        this.model = json.model;
        this.description = json.description;
        this.image = json.image;
        this.code = json.code;
        this.imageLarge = json.imageLarge;
        this.category = json.category;
        this.displayOrder = json.displayOrder;
    
    }
}
