import { Pipe, PipeTransform } from '@angular/core';
/*

*/
@Pipe({name: 'toUppercase'})
export class ToUpperCasePipe implements PipeTransform {
  transform(value: string): string {

    return (value.length > 0 ? (value[0].toUpperCase() + value.slice(1,value.length)) : ''); 
  }

}

@Pipe({name: 'isNull'})
export class NullPipe implements PipeTransform{
  transform(value : string):string{
    return((value == null || value.toLowerCase() == 'null') ? '' : value);
  }
}

