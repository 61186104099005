

export class QA {
//for each question get the questionId, answerValue, productId, and offeringId
    public questionId:number;
    public answerValue:string;
    public productId:number;
    public offeringId:number;
    public product:object;
    public question:object;

    constructor(questionId, answerValue, productId, offeringId, productName, questionText){
        this.questionId = questionId;
        this.answerValue = answerValue;
        this.productId = productId;
        this.offeringId = offeringId;
        this.product = {name:productName};
        this.question = {questionText:questionText}
    }
}
