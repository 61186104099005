import { QA } from './qa';

export class CartItem {

    'productId': number;
    'productName': string;
    'offeringId': number;
    'unit': string;
    'unitPlural': string;
    'unitPrice': number;
    'quantity': number;
    'subtotal': number;
    'image': string;
    'category':string;
    'unitCount':number;
    'unitBase':string;
    public qa: Array<QA> = [];
}  