import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizedService } from '../authorized/authorized.service';
import { Subscription } from 'rxjs';

@Injectable()
export class RouteguardService {
  loginSubscription : Subscription;
  loggedIn : Boolean = false;
  previousRoute : String = '';

  constructor(private authorizedService:AuthorizedService, private router:Router) {
    this.loginSubscription = this.authorizedService.getLoginStatus().subscribe(user => this.loginStatusChanged(user));
  }
loginStatusChanged(user){
  if(user.username != null){
    this.loggedIn = true;

    if(this.previousRoute.length > 0 && this.router.url == '/authenticate'){
      this.router.navigate([this.previousRoute]);
      this.previousRoute = '';
      return true;
    }
    else{
      return true;
    }
  }
  else{//user session timed out
    //this.previousRoute = this.router.url;
    this.loggedIn = false;
    this.authorizedService.routeGuardLoggedOut();
    
  }
}
  canActivate(route : ActivatedRouteSnapshot, state : RouterStateSnapshot):boolean{
    if(this.loggedIn){
      if(state.url == '/authenticate'){//prevent logged in users from accessing authenticate page
        return false;
      }
      return true;
    }
    else{//user is not logged in
      if (state.url != '/authenticate'){//user is being redirected from page requiring authentication
        this.previousRoute = state.url;
        this.router.navigate(['authenticate']);
        return false;
      }
      else{//unlogged in user is trying to access /authenticate
        if(this.authorizedService.previousURL.length > 0){//session time out
          this.previousRoute = this.authorizedService.previousURL;
          this.authorizedService.previousURL = '';
          return true;
        }
        else if(this.previousRoute.length == 0){//user is just typing/visiting /authentication
          this.router.navigate(['home']);
          return false;
        }
        else{//user is being redirected to /authenticate from another page
          return true;
        }
      }
    }
	}
}
