<div class="container">

  <div class="mb-2">
  <h3 class="text-center">CTRP is not responsible for paying customs duties, fees and taxes on research cigarettes.</h3>
  </div>

  <h4>Method</h4>
  <p>
    International orders are generally shipped using DHL. Estimated cost for shipping 1 carton to a few cases is $30/box. Large
    orders of several cases are shipped via UPS Supply Chain Solutions. Estimated cost for shipping using UPS Supply Chain
    Solutions is $250. If a customer would like an order shipped by any other means, all shipping paperwork must be provided
    with the order.
  </p>

  <h4>Invoices</h4>
  <p>
  Commercial Invoices and Proforma Invoices are not a request for payment. Invoices will be sent after the product is shipped
  that includes both the product cost and shipping cost. This invoice will include payment information/instructions.
  </p>

  <h4>Purchase Orders</h4>
  <p>
    Receipt of a Purchase Order from an institution does not consitute an order. All orders must by placed on this website and
    a fully completed disclaimer (terms and conditions) form attached. Purchase Orders may be uploaded during the order process.
    Purchase orders emailed to ctrp&#64;uky.edu must identify the web order ID number or it may not be properly matched with
    an online order. Uploading the PO during the order process alleviates any delays while POs are matched with online orders.
    There are NO additional terms and conditions, and any attempt to add via a purchase order or otherwise is VOID.
  </p>

  <h4>Incoterms</h4>
  <p>
    The Incoterms rules or International Commercial Terms are a series of pre-defined commercial terms published by the International
    Chamber of Commerce (ICC). They are widely used in International commercial transactions or procurement processes. A
    series of three-letter trade terms related to common contractual sales practices, the Incoterms rules are intended primarily
    to clearly communicate the tasks, costs, and risks associated with the transportation and delivery of goods.
  </p>

  <h4>EXW – Ex Works (named place of delivery)</h4>
  <p>
    The seller makes the goods available at their premises. This term places the maximum obligation on the buyer and minimum
    obligations on the seller.>
  </p>
  <p>
    EXW if customer has product picked up from CTRP.
  </p>

  <h4>CPT – Carriage Paid To (named place of destination)</h4>
  <p>
    CPT replaces the venerable C&amp;F (cost and freight) and CFR terms for all shipping modes outside of non-containerized sea
    freight.
  </p>
  <p>
    The seller pays for the carriage of the goods up to the named place of destination. Risk transfers to buyer upon handing
    goods over to the first carrier at the place of shipment in the country of Export. The seller is responsible for origin
    costs including export clearance and freight costs for carriage to named place of destination (either final destination
    such as buyer's facilities or port of destination has to be agreed by seller and buyer, however, named place of destination
    is generally picked due to cost impacts). If the buyer does require the seller to obtain insurance, the Incoterm CIP
    should be considered.
  </p>

  <h4> FOB – Free on Board (named port of shipment)</h4>
  <p>
    Under FOB terms the seller bears all costs and risks up to the point the goods are loaded on board the vessel. The seller
    must also arrange for export clearance. The buyer pays cost of marine freight transportation, bill of lading fees, insurance,
    unloading and transportation cost from the arrival port to destination. Since Incoterms 1980 introduced the FCA incoterm,
    FOB should only be used for non-containerized sea freight and inland waterway transport. However, FOB is still used for
    all modes of transport despite the contractual risks that this can introduce.
  </p>
</div>