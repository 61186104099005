import { CartItem } from './cartitem';
import { Addresses } from './addresses';

export class Cart {

    public CartItems: Array<CartItem> = [];

    public Addresses: Addresses;

    constructor() {

    }

    addProduct(cartObject: CartItem) {

        this.CartItems.push(cartObject);

    }

    getCartItemsCount() {
       return this.CartItems.length;
    }

    hasOffering(offering){
        var found = false;
        var i = 0;
        while(!found && i < this.CartItems.length){
            if(this.CartItems[i++].offeringId == offering){
                found = true;
            }
        }
        if(found){
            return --i;
        }
        return -1;
    }
    hasProduct(productId){
        var found = false;
        var i = 0;
        while(!found && i < this.CartItems.length){
            if(this.CartItems[i++].productId == productId){
                found = true;
            }
        }
        if(i == this.CartItems.length){
            i = -1;
        }
        return i;
    }

}
