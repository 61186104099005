<div class="container-fluid">
    <div class="col-12" [hidden]="hasProfstudies==true">

        <p>You haven't purchased any Proficiency Studies yet!</p>
    </div>
    
    <div class="row" [hidden]="hasProfstudies==false">
        <div class="col-md-3">
            <ng-container *ngFor="let item of profstudyObject | keyvalue:valueOrder">
            <div *ngIf="item.value.profstudies.length > 0">
            <ng-container *ngIf="item.value.hidden == false; then catActive; else catInactive"></ng-container>
            <ng-template #catActive>
                <a class="h5 pproduct pproduct-category" (click)="toggleProfStudies(item.key)">{{item.key}}
                <span class="badge rounded-pill text-white text-bg-primary">{{item.value.profstudies.length}}</span>
                <ng-container *ngIf="item.value.hidden == false then expanded; else collapsed"></ng-container></a>
            </ng-template>
            <ng-template #catInactive>
                <a class="h5 pproduct pproduct-category" (click)="toggleProfStudies(item.key)">{{item.key}}
                <span class="badge rounded-pill text-white text-bg-primary">{{item.value.profstudies.length}}</span>
                <ng-container *ngIf="item.value.hidden == false then expanded; else collapsed"></ng-container></a>
            </ng-template> 
        
                <div [id]="item.key" [ngbCollapse]="item.value.hidden">
                  <ng-container *ngFor="let r of item.value.profstudies">
                  <ng-container *ngIf="r.roundName === activeRound; then active; else notActive"></ng-container>
                  
                    <ng-template #active>
                        <a class="active2 pproduct pproduct-item" [routerLink]="['/profstudies', r.roundName]">{{r.roundName}}
                            <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span>
                        </a>
                    </ng-template>
                    <ng-template #notActive>
                        <a class="pproduct pproduct-item" [routerLink]="['/profstudies', r.roundName]" (click)="updateActiveRound(r.roundName)">{{r.roundName}}
                            <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span>
                        </a>
                    </ng-template>
                  </ng-container>
        
                </div>
          </div>
        </ng-container>
        </div>
        <!--<div class="col-md-3">
            <ul style="list-style-type: none;" class="h5">
                <ng-container *ngFor="let item of profstudyObject | keyvalue:valueOrder">
                    <li *ngIf="item.value.profstudies.length > 0" class="ctrp-list-item" (click)="toggleProfStudies(item.key)">
                        {{item.key}}
                        <span class="badge rounded-pill text-white text-bg-primary">{{item.value.profstudies.length}}</span> 
                        <ng-container *ngIf="item.value.hidden == false then expanded; else collapsed"></ng-container>
                        <ul style="list-style-type: none;" class="h6" [hidden]="profstudyObject[item.key].hidden">
                            <li *ngFor="let r of item.value.profstudies">
                                <ng-container *ngIf="r.roundName === activeRound; then active; else notActive"></ng-container>
                                <ng-template #active>
                                    <a style="text-decoration:none;" id="item.key"[routerLink]="['/profstudies', r.roundName]" (click)="updateActiveRound(r.roundName)">{{r.roundName}} <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span></a>
                                </ng-template>
                                <ng-template #notActive>
                                    <a style="text-decoration:none;" data-toggle="collapse" (click)="updateActiveRound(r.roundName)" [routerLink]="['/profstudies', r.roundName]">{{r.roundName}} <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span></a>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ul>-->
            <!--<ng-container *ngFor="let item of profstudyObject | keyvalue:valueOrder">
                <div class="ctrp-menu menu-category myborder">
                <a *ngIf="item.value.profstudies.length > 0" class="ctrp-menu menu-category h5 myborder" (click)="toggleProfStudies(item.key)">{{item.key}}
                    <span class="badge rounded-pill text-white text-bg-primary">{{item.value.profstudies.length}}</span> 
                    <ng-container *ngIf="item.value.hidden == false then expanded; else collapsed"></ng-container></a>
                </div>
                    <div [hidden]="profstudyObject[item.key].hidden">
                        <ng-container *ngFor="let r of item.value.profstudies">
                            <ng-container *ngIf="r.roundName === activeRound; then active; else notActive"></ng-container>
                            <ng-template #active>
                                <a class="ctrp-menu menu-item active h6" id="item.key"[routerLink]="['/profstudies', r.roundName]" (click)="updateActiveRound(r.roundName)">{{r.roundName}} <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span></a>
                            </ng-template>
                            <ng-template #notActive>
                                <a class="ctrp-menu menu-item h6" data-toggle="collapse" (click)="updateActiveRound(r.roundName)" [routerLink]="['/profstudies', r.roundName]">{{r.roundName}} <span class="badge rounded-pill text-bg-secondary">{{r.purchasedStudies.length}}</span></a>
                            </ng-template>
                        </ng-container>
                </div>
                </ng-container>-->
        
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>

<ng-template #expanded>
        <span class="bi bi-caret-down-fill text-black"></span>
</ng-template>
<ng-template #collapsed>
        <span class="bi bi-caret-right-fill text-black"></span>
</ng-template>