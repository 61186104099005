<div class="row content">
  <div class="col-12">
    <h2 class="p-2 border-bottom border-light">
      Shopping Cart
    </h2>

    <nav aria-label="Order navigation">
      <ul class="pagination justify-content-center" id="cartNav">
        <li class="page-item">
          <a class="page-link" [routerLink]="['verifyorder']" routerLinkActive="bg-primary text-white"> 1. Verify Order</a>
        </li>
        <li class="page-item">
          <a class="page-link" routerLink="/cart/addresses" routerLinkActive="bg-primary text-white">2. Addresses</a>
        </li>
        <li class="page-item">
          <a class="page-link" routerLink="questions" routerLinkActive="bg-primary text-white">3. Additional Questions</a>
        </li>
        <li class="page-item">
          <a class="page-link" routerLink="/cart/finalize" routerLinkActive="bg-primary text-white">4. Finalize Order</a>
        </li>
      </ul>
    </nav>

    <router-outlet></router-outlet>
  </div>
</div>
