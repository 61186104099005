import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-filepicker',
  templateUrl: './filepicker.component.html',
  styleUrls: ['./filepicker.component.css']
})
export class FilePickerComponent implements OnInit {
  filelist : FileList;
  isValid : boolean;
  submitted : boolean = false;
  public
  @Output() onSubmitEvent = new EventEmitter<any>();

  constructor(public activeModal:NgbActiveModal, public http:HttpClient) {}


  ngOnInit() {

  }
 
onSubmit(){
  if(!this.submitted){
    this.onSubmitEvent.emit(this.filelist);
  }

  this.submitted = true;

}
successfulResponse(res){
  this.onSubmitEvent.emit(res);
}
errorResponse(err){
  //console.log(err);
}
handleFileInput(files:any){
 if(files.length == 0){
    this.isValid = false;
    this.filelist = null;
  }
  else{
    this.isValid = true;
    this.filelist = files;
  }
}
}