import { Component, Input } from '@angular/core';
import { MessageService } from '../../../services/message/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
@Component({
  selector: 'app-profstudyreview',
  templateUrl: './profstudyreview.component.html',
  styleUrls: ['./profstudyreview.component.css']
})
export class ProfstudyreviewComponent {

  @Input() confirmData : any;
  @Input() machineData : any;
  @Input() sectionData : any;
  @Input() filename : any;
  @Input() submitData : any;
  @Input() study : any;
  @Input() physicalData : any;
  constructor(private messageService : MessageService, public activeModal : NgbActiveModal, private authorizedService : AuthorizedService) {
  }

submitClicked(){
  this.authorizedService.addMask('Submitting Data...');
  var formdata = new FormData();
  formdata.append('profstudy', this.submitData);
  formdata.append('filename',this.filename);
  this.authorizedService.postFile("refcig-services/profstudydata",formdata,this.submissionSuccess,this.submissionError,this);
}
submissionSuccess(){
  this.authorizedService.removeMask();
  this.messageService.createMessage('success','Data successfully submitted!');
  this.study.status = 'complete';
  this.activeModal.close();
}
submissionError(res){
  this.activeModal.close();
}

}
