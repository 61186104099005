<h1 class="text-center">
        2023 TSRC Announcement
</h1>

<h3 class="text-center">Center for Tobacco Reference Products</h3>
<h3 class="text-center">Cigar Tobacco Reference Products Program Update</h3>

<h4 class="text-center">
        1:05-1:35pm on September 27<sup>th</sup> as the final presentation for the 76<sup>th</sup> Tobacco Science Research 
        Conference<br>
        <span style="font-style: italic;">Norfolk, Virginia USA</span>
</h4>
<p>
        This presentation is meant to engage stakeholders to obtain feedback on tobacco reference products and programs 
        being initiated within the CTRP with support from the FDA’s Center for Tobacco Products and the industry.  
</p>
<p>
        <strong>New Certified Reference Cigars.  </strong>As part of a collaborative agreement with the FDA, 
        CTRP has engaged stakeholders for the design, production, and characterization of three new certified reference cigars: 
        large cigar (1RLC), cigarillo (1RSC), and filtered cigar (1RFC).  An update on the progress of the project will be provided 
        and stakeholder feedback will be encouraged. All three products have been produced and received by the University of Kentucky.  
        Characterization is complete for the 1RLC and a Certificate of Analysis is being produced in collaboration with the FDA.  
        Characterization is ongoing for 1RSC and 1RFC and a Certificate of Analysis will be generated for each product once the analysis is complete.  
        The new certified reference cigar products will be added to the proficiency testing program by 2025.  
        Feedback from stakeholders is encouraged to help develop the most effective proficiency testing program to 
        further cigar tobacco science knowledge. 
</p>

<div style="height: 100px; overflow-y: scroll;" class="d-sm-none">
        <img class="rounded float" src="../../assets/img/tsrc1.jpg" width="33%" height="100%">
        <img class="rounded float ms-1 me-1" src="../../assets/img/tsrc2.jpg" width="33%" height="100%">
        <img class="rounded float mt-1" src="../../assets/img/tsrc3.jpg" width="33%" height="100%">

</div>
<div style="height: 200px; overflow-y: scroll;" class="d-none d-sm-block d-md-none">
        <img class="rounded float" src="../../assets/img/tsrc1.jpg" width="33%" height="100%">
        <img class="rounded float ms-1 me-1" src="../../assets/img/tsrc2.jpg" width="33%" height="100%">
        <img class="rounded float mt-1" src="../../assets/img/tsrc3.jpg" width="33%" height="100%">

</div>
<div style="height:300px;" class="d-none d-md-block">
        <img class="rounded float" src="../../assets/img/tsrc1.jpg" width="33%" height="100%">
        <img class="rounded float ms-1 me-1" src="../../assets/img/tsrc2.jpg" width="33%" height="100%">
        <img class="rounded float mt-1" src="../../assets/img/tsrc3.jpg" width="33%" height="100%">
        </div>
<p style="font-style: italic;">
        Funding for this presentation was made possible, in part, by the Food and Drug Administration through 
        grants RFA-FD-14-001, RFA-FD-15-026 and RFA-FD-20-002. The views expressed in written materials or publications 
        and by speakers and moderators do not necessarily reflect the official policies of the Department of Health 
        and Human Services; nor does any mention of trade names, commercial practices, or organization imply 
        endorsement by the United States Government. 
</p>

<h2>Videos of Previous Workshop Presentations:</h2>
<div class="row">
        <div class="col-md-auto ms-1"><a class="h4" href="https://youtu.be/aoHTmgF3Y-I">2022 Video Link</a></div>
        <div class="col-md-auto">
                <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!is2022Collapsed"
                  aria-controls="collapseExample">
                  View/Hide Announcement
                </button>
        </div>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="is2022Collapsed">
<p class="h1 text-center">Center for Tobacco Reference Products Workshop</p>
<p class="h2 text-center">3:00-5:00 pm on September 11<sup>th</sup> prior to the start of the <a href="http://www.tsrcinfo.com" target="_blank">75<sup>th</sup> Tobacco Science Research Conference</a></p>
<p class="h2 text-center"><em>New Orleans, Lousianna USA</em></p>

<div class="card-body p1">
    <p class="card-text">
            <strong>There is no cost to attend the workshop.</strong>  For those unable to attend in person, a virtual option will be available.    
            To access the virtual option, participants are required to register by <strong> an e-mail to <a href="mailto:ctrp&#64;uky.edu">ctrp&#64;uky.edu</a>  A link for the workshop will be sent to <span style="color:red;">ONLY</span> registered participants.  
            Registration for the TSRC virtual option does <span style="color:red;">NOT</span> automatically register participants for the CTRP workshop.</strong>  
            This workshop is meant to engage stakeholders to obtain feedback on tobacco reference products and programs being initiated within the CTRP with support from the FDA’s Center for Tobacco Products and the industry. 
            Topics to be discussed include:
            </p>
            <h4>Reference Tobacco Products for Proficiency Testing.</h4>
            <p>
            CTRP has offered 21 rounds of proficiency testing using the certified 1R6F reference cigarette and 3 rounds of proficiency testing using the certified reference smokeless tobacco products.  
            Stakeholder feedback in encouraged for determination of parameters of interest in future rounds of testing to update the testing schedule and inclusion of new reference products in the testing program.
        </p>
            <h4>New Certified Reference Cigars.</h4> 
            <p>
            As part of a collaborative agreement with the FDA, CTRP has engaged stakeholders for the design, production, and 
            characterization of 3 new certified reference cigars: large cigar (1RLC), cigarillo (1RSC), and filtered cigar (1RFC).  
            An update on the progress of the project will be provided and stakeholder feedback will be encouraged.
    </p>
    
            <h4>Product display and discussion.</h4>  
            <p>
            Examples of all CTRP reference products will be on display along with CTRP staff to discuss current and future research initiatives and proficiency testing plans.  
            Input from TSRC participants is welcome and encouraged.
    </p>
    <p style="font-style: italic">            
            Funding for this workshop was made possible, in part, by the Food and Drug Administration through grants RFA-FD-14-001, RFA-FD-15-026 and RFA-FD-20-002. 
            The views expressed in written materials or publications and by speakers and moderators do not necessarily reflect the official policies of the Department of Health 
            and Human Services; nor does any mention of trade names, commercial practices, or organization imply endorsement by the United States Government.     
    </p>
</div>
</div>
<hr>

        <div class="row">
        <div class="col-md-auto ms-1"><a class="h4" href="https://youtu.be/_U4dxOn6n04">2021 Video Link</a></div>
        <div class="col-md-auto">
                <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                  aria-controls="collapseExample">
                  View/Hide Announcement
                </button>
        </div>
        </div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
               
              
<div class="card-body p1">
    
        <p class="h1 text-center">University of Kentucky Center for Tobacco Reference Products Workshop</p>
        <p class="h2 text-center">3:00-5:00 pm on August 29<sup>th</sup> prior to the start of the 74<sup>th</sup> Tobacco Science Research Conference</p>
        <p class="h2 text-center"><em>Boston, Massachusetts USA</em></p>
<div class="row">
        <div class="col-1">3:00</div>
        <div class="col-11"><strong>Stacey Slone, MS, Dr. Bing Zhang Department of Statistics at the University of Kentucky.</strong>
                The statistical approach using consensus values for the proficiency testing program scoring will be discussed and input solicited from stakeholders.  
                The impact of small sample sizes in the initial round of testing of the four reference smokeless products will be evaluated.  
        </div>
</div><br/>
<div class="row">
        <div class="col-1">3:25</div>
        <div class="col-11"><strong>Ruth McNees, Ph.D., University of Kentucky Center for Tobacco Reference Products. </strong>
                The results from multiple rounds of testing for selected HPHCs in the mainstream smoke of the 1R6F reference cigarette will be presented.  
                An update on the proficiency testing program using the certified reference tobacco products will be provided.  
                Recent expansion of the CTRP product line and preliminary discussion of future products will be introduced, including the design and production of three certified cigar references. 
                </div>
</div><br/>
<div class="row">
        <div class="col-1">3:50</div>
        <div class="col-11"><strong>Product display and discussion. </strong>
                Examples of all CTRP reference products will be on display along with CTRP staff to discuss current and future research initiatives and proficiency testing plans.  
                Input from TSRC participants is welcome and encouraged.</div>
</div>
<br/>
<p style="font-style: italic">            
                Funding for this workshop was made possible, in part, by the Food and Drug Administration through grants RFA-FD-14-001, RFA-FD-15-026 and RFA-FD-20-002. 
                The views expressed in written materials or publications and by speakers and moderators do not necessarily reflect the official policies of the Department of Health 
                and Human Services; nor does any mention of trade names, commercial practices, or organization imply endorsement by the United States Government.     
        </p>
 
</div>
</div>
