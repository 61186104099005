import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-machinedata',
  templateUrl: './machinedata.component.html',
  styleUrls: ['./machinedata.component.css']
})
export class MachinedataComponent implements OnInit {

  @Input('machineData')machineData : any;
  constructor() { 

  }

  ngOnInit() {
  }

}
