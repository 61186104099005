 


<h1>Reference Product Information</h1>
<p>
<a href="/assets/pdf/webdocs/Mainstream_smoke_constituents_and_in_vitro_toxicity_comparative_analysis_of_3R4F_and_1R6F_reference_cigarettes.pdf">Mainstream smoke constituents and in vitro toxicity comparative analysis of 3R4F and 1R6F reference cigarettes</a><br/>
<a href="/assets/pdf/webdocs/2015%20Variation%20in%20tobacco%20article%20-%203R4F.pdf">2015 Variation in tobacco article - 3R4F.pdf</a><br/>
<a href="/assets/pdf/webdocs/Mainstream%20Smoke%20Chemistry%203R4F,%202R4F.pdf">Mainstream Smoke Chemistry 3R4F, 2R4F.pdf</a><br/>
<a href="/assets/pdf/webdocs/Storage%20Information%20-%20Ref%20Cigs.pdf">Storage Information for Reference Cigarettes.pdf</a><br/>
<a href="/assets/pdf/webdocs/smokeless_tobacco_research_products_booklet.pdf">Smokeless Tobacco Research Booklet.pdf</a><br/>
</p>

<h2>Website Help</h2>
<p>
<a href="/assets/pdf/webdocs/How_to_Place_an_Order_Manual.pdf">How to Place an Order</a><br/>
<a href="/assets/pdf/webdocs/How_to_Upload_Proficiency_Test_Data.pdf">How to Upload Proficiency Test Data</a><br/>
<a href="/assets/pdf/webdocs/How_to_Submit_Comments_to_the_Interim_Proficiency_Report.pdf">How to Submit Comments to the Interim Report</a><br/>
</p>