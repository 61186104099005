
<div [hidden]="status == 'success'">
<div class="modal-header">
  <h4 class="modal-title">Error</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]=message></p>
</div>
</div>

<!--Successful Messag-->
<div [hidden]="status == 'error'">
    <div class="modal-header">
      <h4 class="modal-title">Success!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
</div>