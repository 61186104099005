import { Component, NgModule, Input, OnInit, OnChanges } from '@angular/core';
//import { ReactiveFormsModule, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

//import { Cart } from '../../../models/cart';
//import { Addresses } from '../../../models/addresses';
import { CartService } from '../../../services/cart/cart.service';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/message/message.service';
import { OrdersService } from '../../../services/orders/orders.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finalize',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.css']
})
export class FinalizeComponent implements OnInit {

  canEdit : boolean = false;
  completeSubscription : Subscription;
  responses : any;
  answerFormValid : boolean = false;
  addressFormValid : boolean = false;
  showCart : boolean = false;
  constructor(public cartService : CartService, 
              public authorizedService : AuthorizedService, 
              private messageService : MessageService,
              private orderService : OrdersService,
              private router : Router) {
              this.canEdit = false; 
    
  }

  ngOnInit() {
    if(this.cartService.cart.CartItems != null && this.cartService.cart.CartItems.length > 0){
      this.showCart = true;
    }

    if(this.cartService.addressesForm !=null && this.cartService.addressesForm.valid == true){//address form is valid
      this.addressFormValid = true;
    }
    if(this.cartService.answerForm != null && this.cartService.answerForm.valid == true){//answers form is valid
      this.answerFormValid = true;
    }
    
    if(this.cartService.answerForm != null && this.cartService.answerForm.valid)
      this.responses = this.cartService.convertQuestionFormToResponses();
  }
confirmPurchase(){
  //add mask to prevent double clicking button
  this.authorizedService.addMask('Submitting Purchase....');

  var data = {};
  var shippingInstructionsPONumber = {} 
  var additionalInfo = {};
  var addresses = this.cartService.getAddresses();

  shippingInstructionsPONumber['poNumber'] = addresses.shipping.po;
  shippingInstructionsPONumber['shippingInstructions'] = addresses.shipping.instructions;
  shippingInstructionsPONumber['taxId'] = addresses.shipping.taxid;

  data['cartentries'] = this.cartService.getCart().CartItems;
  data['qa'] = this.flattenResponses();
  additionalInfo['authority'] = this.authorizedService.userInfo.authorityInstitution[0].authority;
  additionalInfo['institution'] = this.authorizedService.userInfo.authorityInstitution[0].institution;
  additionalInfo['institutionType'] = this.cartService.getResponses().institutionType;
  additionalInfo['termsAndConditions'] = 'false';

  data['shippingInstructionsPONumber'] = shippingInstructionsPONumber;
  data['additionalInfo'] = additionalInfo;

  data['billing'] = addresses.billing;
  data['shipping'] = addresses.shipping; 

  this.authorizedService.completePurchase(data,this.purchaseResponse.bind(this));
}

purchaseResponse(res){
  //purchase was successfull, reset cart and request orders/profstudies
  this.authorizedService.removeMask();
  this.authorizedService.getOrders();
  this.authorizedService.getProfStudies();
  this.messageService.createMessage('success', 'Purchase confirmed you can view your order in the My Orders section');
  this.cartService.resetCart();
  this.router.navigate(['/orders/'+res.id]);
}

flattenResponses(){
  //since responses are an array of array the outside array needs to be removed
  var r = [];
  for(var i =0; i< this.responses.length;i++){
    for(var j=0; j< this.responses[i].length; j++){
      r.push(this.responses[i][j]);
    }
  }
  return r;
}

}
