import { Component, OnInit } from '@angular/core';
import { AuthorizedService } from '../../services/authorized/authorized.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    loginSubscription : Subscription;
    loggedIn : boolean;
  constructor(authorizedService : AuthorizedService) {
    this.loggedIn = false;
    this.loginSubscription = authorizedService.getLoginStatus().subscribe(user => {if(user.username!=null){this.loggedIn = true} else{this.loggedIn = false}});
  }

  ngOnInit() {

  }

}
