import { Component, OnInit } from '@angular/core';
import {Registration} from '../../models/registration';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClient,HttpHeaders} from '@angular/common/http';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {

  submitted: boolean;
  submittedFormResponse: string;

  registrationModel = new Registration();

  valueA: number;
  valueB: number;
  valueC: number;

  showAnswerWarning: boolean;

  private submitNewUserUrl = 'refcig-services/newUserRegistration';


  submitNewUser(): void{

    //set form status from response
    this.http.post(this.submitNewUserUrl, this.registrationModel,{responseType: 'text'}).subscribe(res => this.submittedFormResponse = res,err=>console.log(err));

  }

  onSubmit(){

      if(Number(this.registrationModel.getAnswer()) == this.valueC){    
        this.showAnswerWarning = false;
        this.submitNewUser();
      }else{
        this.showAnswerWarning = true;
        this.generateNewRandom();
        //console.log(this.registrationModel);
      }

      this.resetForm(); //reset form
      this.submitted = true;
  }

  resetForm(){

    this.generateNewRandom(); //generate new random
    this.registrationModel.reset; //reset all value to null

  }

  get diagnostic() { return JSON.stringify(this.registrationModel); }

  constructor(private http : HttpClient) { 
   
    this.submitted = false;
    this.showAnswerWarning = false;
    this.generateNewRandom();
  }

  ngOnInit() {
  }

  generateNewRandom(){
      this.valueA = Math.floor(Math.random() * 9) + 1;
      this.valueB = Math.floor(Math.random() * 9) + 1;
      this.valueC = this.valueA + this.valueB;
  }
}
