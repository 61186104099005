import { Component, OnInit, Input } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Cart } from '../../../models/cart';
import { CartService } from '../../../services/cart/cart.service';
import { CartItem } from '../../../models/cartitem';
import { FormArray, FormBuilder, FormsModule, Validators } from '@angular/forms';
import { ReactiveFormsModule, FormControl } from '@angular/forms';

@Component({
  selector: 'app-verifyorder',
  templateUrl: './verifyorder.component.html',
  styleUrls: ['./verifyorder.component.css']
})


export class VerifyorderComponent implements OnInit {
 
  @Input('canEdit') canEdit : boolean;
  cart;
  qa;
  showCart = false;
  cartTotal = 0;
  cartFormArray;
  cartForm = this.fb.group({
    items: this.fb.array([])
  });

  constructor(
    private router: Router,
    public cartService: CartService,
    private route: ActivatedRoute,
    private fb:FormBuilder
  ) {
    router.events.subscribe((val) => {
     });

     this.cartService.cart.CartItems.forEach(element => {
        const itemForm = this.fb.group({
          quantity:[element.quantity,[Validators.pattern(/^\d*$/),Validators.min(1)]]
        });
        var fa = this.cartForm.controls["items"] as FormArray;
        fa.push(itemForm);
     });
     //debugger;
  }

  ngOnInit() {
    this.setCartTotal();
    this.cart = this.cartService.cart;

    if (this.cart.CartItems.length > 0) {
      this.showCart = true;
    }


  }
  get items(): FormArray{
    return this.cartForm.controls["items"] as FormArray;
  }
  updateTotal(item: CartItem) {
    //console.log('update total');
    item.subtotal = item.quantity * item.unitPrice;
    this.setCartTotal();
    //debugger;
  }

  setCartTotal() {
    this.cart = this.cartService.cart;
    this.cartTotal = 0;

    this.cart.CartItems.forEach((prod, i) => {
      this.cartTotal += prod.subtotal;
    });

  }
updateSubtotal(event:any,i:any){
  this.cart.CartItems[i].quantity = event.target.value;
  this.updateTotal(this.cart.CartItems[i]);
}
  removeFromCart(evt: any, cartItem: CartItem) {
    //debugger;
    var cartIndex = this.cartService.removeProductFromCart(cartItem);
    this.cartForm.controls.items.removeAt(cartIndex)
    if (this.cartService.getCartQuantity() < 1) {
      this.showCart = false;
    }

    this.setCartTotal();

  }

}
