import { Component} from '@angular/core';
import { ProfstudyService } from '../../services/profstudy/profstudy.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import {KeyValue} from '@angular/common';
import { Profstudymenu, ProfstudyObject } from '../../models/profstudymenu';
@Component({
  selector: 'app-',
  templateUrl: './profstudy.component.html',
  styleUrls: ['./profstudy.component.css']
})
export class ProfstudyComponent {

  profstudySubscription:Subscription;
  roundSubscription:Subscription;
  //profstudyArray: Array<any> = [];
  //object with years as keys.  Each year has array of purchased studies
  profstudyObject: ProfstudyObject;
  hasProfstudies = false;
  yearReg = /\d{4}/;
  id = 0;
  activeRound = '';
  constructor(private profstudyService:ProfstudyService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.profstudyObject = new ProfstudyObject();
        this.activeRound = this.router.url.split("/")[3];

        this.profstudySubscription = this.profstudyService.profstudySubject.subscribe(profstudies => this.recievedProfstudies(profstudies));
        //this.roundSubscription = this.profstudyService.roundIdSubject.subscribe(roundName => this.updateActiveRound(roundName));
  }
  ngOnDestroy(){
    //console.log("die!!");
          //this.roundSubscription.unsubscribe();
    this.profstudySubscription.unsubscribe();
  }
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    //debugger;
    if(a.key<b.key){
      return 1;
    }
    return -1;
  }
  toggleProfStudies(key){
    this.profstudyObject[key].hidden = !this.profstudyObject[key].hidden;
  }
  recievedProfstudies(profstudies:any){
    //debugger;
    profstudies.sort(function comparefn(e1,e2){
      var round1 = e1.roundName.split("-")[1];
      var round2 = e2.roundName.split("-")[1];
      if(round1 < round2){
        return -1;
      }
      if(round1 > round2){
        return 1;
      }
      return 0;
    });
    this.profstudyObject = new ProfstudyObject();//reset to prevent double adding if a new profstudy is purchased
    if(profstudies.length > 0){
      this.hasProfstudies = true;
    }
    
    //var years = Object.keys(this.profstudyArray);
    for(var i = (profstudies.length - 1); i>-1; i--){
      //for (var i=0; i<profstudies.length; i++){
      var a = this.yearReg.exec(profstudies[i].roundName);
        if(a != null){
          this.profstudyObject[a[0]].profstudies.push(profstudies[i]);
        }
    }

      //on purchase success the profstudies are reloaded. if on orders page then don't navigate.
      //on navigating from another tab to "my proficiency studies", first child will be null
      //so navigate to last prof study round or the most recent
      if(this.activatedRoute.firstChild == null){
        //debugger;
        if(this.profstudyService.activeRound == null){
          this.activeRound = profstudies[profstudies.length-1].roundName;
          //debugger;
          this.router.navigateByUrl('profstudies/'+profstudies[profstudies.length-1].roundName);
        }
        else{
          this.router.navigateByUrl('profstudies/'+this.profstudyService.activeRound);
        }
      }
      this.updateRoundMenu();
  }
updateRoundMenu(){
  //when refreshing the page or coming from a link, the menu needs to be updated so that the active round's year
  //is expanded
  //debugger;
  if(this.activeRound == '' || this.activeRound == null){
    this.activeRound = this.profstudyService.activeRound;
  }
    var year = this.yearReg.exec(this.activeRound);
    if (year != null){
      var currentYear = new Date().getFullYear();
      for(var i = 2016; i<=currentYear; i++){
        if(i.toString() == year[0]){
          this.profstudyObject[i].hidden = false;
        }
        else{
          this.profstudyObject[i].hidden = true;
        }
      }
    }
  }

//used by the html on click to update the active/blue highlighted round
updateActiveRound(roundName){
  this.activeRound = roundName;
  this.profstudyService.activeRound = roundName;
}

}
