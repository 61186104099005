<!-- Modal dialog for large image display -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ selectedProduct.name }}</h4>
  </div>
  <div class="modal-body">
    <img src="../../assets/img/{{ selectedProduct.imageLarge}}" class="rounded mx-auto d-block w-100 h-100"/>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<!-- Id not found error message -->
<div *ngIf="errorMessage !== ''">
  <h3>{{ errorMessage }} </h3>
</div>

<div *ngIf="selectedProduct">

  <h3 class="text-secondary mb-2">{{ selectedProduct.name | uppercase }}</h3>
  <div class='container-fluid mt-3'>
    <div class='row'>

      

      <!-- Third column -->

      <div class='col-md-3 order-md-3 m-0 mb-5 p-0'>
        <div *ngIf="getProductAvailability() == NOT_YET">
          This product will be available starting {{selectedProduct.startDate}}.
        </div>
        <div *ngIf="getProductAvailability() == NOT_ANYMORE">
          This product is no longer available.
        </div>
  
        <!-- <div *ngIf="loggedIn"> -->

            <form [formGroup]='detailForm' (ngSubmit)="addToCart(detailForm)" *ngIf="getProductAvailability() == AVAILABLE">

              <div class="input-group">

                <div class="input-group-prepend">
                  <span class="input-group-text">Qty.</span>
                </div>
                <input type="text" class="form-control" formControlName="quantity" type="number" min="1" step="1">
                <ngb-alert type="danger" [dismissible]="false" *ngIf="detailForm.get('quantity').invalid && (detailForm.get('quantity').dirty || detailForm.get('quantity').touched)">
                  Quantity cannot be &lt; 1 or contain decimals.</ngb-alert>
              </div>

              <div class="input-group mt-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Unit</span>
                </div>
                <select class="form-control" formControlName="unit">
                  
                  <ng-container *ngFor="let o of selectedProduct.offerings; index as i">
                    <option [value]="o.id">
                        <ng-container *ngIf="o.unit=='bottle'; then bottles; else nobottles"></ng-container>
                        <ng-template #bottles>
                            {{o.unit}} {{o.unitBase}} 
                        </ng-template>
                        <ng-template #nobottles>
                            {{o.unit}} ({{o.unitCount  | currency:'':'':'1.0-0'}}
                            <ng-container *ngIf="o.unitCount > 1">{{o.unitBasePlural}})</ng-container>
                            <ng-container *ngIf="o.unitCount <= 1">{{o.unitBase}})</ng-container>
                        </ng-template>
                    </option>
                  </ng-container>
                </select>
              </div>
              
              <button *ngIf="loggedIn" type="submit" class="btn btn-outline-primary btn-sm mt-2" [disabled]="detailForm.invalid">Add to Cart</button>
              <div *ngIf="!loggedIn" class="bg-danger text-white p-1 m-1 rounded">You must log in to make purchases!</div>
              <div class='mt-4 ms-1 me-1 ps-1 pe-1 bg-light text-secondary'>
                <div *ngIf="unitPrice != null">
                <label class='col col-form-label font-weight-bold ps-0'>
                  Unit Price:
                  <span id='subTotal'>{{ unitPrice | currency }}</span>
                </label>
                </div>
                <div *ngIf="!detailForm.invalid">
                <label class='col col-form-label font-weight-bold ps-0'>
                  Subtotal:
                  <span id='subTotal'>{{ subTotal | currency }}</span>
                </label>
                </div>
              </div>
            </form>
            <!--display if subtotal is > 0-->
            <div *ngIf="cartService.subtotal > 0">
                <hr>
                <div class='mt-4 ms-1 me-1 ps-1 pe-1 bg-light text-secondary font-weight-bold'>
                  Cart Subtotal: {{cartService.subtotal | currency}}
                </div>
                <hr> 
            </div>
            
          <div *ngIf="displayCheckoutCard">
            <div class="alert alert-success ms-1 me-1">
                Products added to cart!
            </div>
            
            <div class="card ms-1 me-1 mt-2 text-center">

              <div class="card-body m-0 bg-light">
                <a class="btn btn-outline-primary btn-sm mt-1 text-right" style="white-space: normal;" routerLink="/cart/verifyorder">Proceed to Checkout?</a>
              </div>

            </div>

          </div>
        <div *ngIf="!loggedIn">

        </div>
      </div>

      <div class='col-md-7 p-2 pe-5 order-md-2' innerHTML="{{selectedProduct.description}}"></div>
      <div class='col-md-2 order-md-1 p-0'>
        
          <ng-container *ngIf="selectedProduct.imageLarge == null; then noLargeImage; else largeImage"></ng-container>
          <ng-template #largeImage>
              <img *ngIf="selectedProduct.image !=null" style="cursor: pointer;" (click)="openLargeImage(content,selectedProduct.category)" src="../../assets/img/{{ selectedProduct.image}}" width='80' class="rounded mx-auto d-block img-responsive" />
              <figcaption class="text-muted mt-1 text-center">
                <small>click to zoom</small>
              </figcaption>
          </ng-template>
  
          <ng-template #noLargeImage>
              <img *ngIf="selectedProduct.image != null" src="../../assets/img/{{ selectedProduct.image}}" width='80' class="rounded mx-auto d-block img-responsive" />
          </ng-template>
        </div>
    </div>

  </div>
  <!--<app-carousel-ng></app-carousel-ng>-->
</div>