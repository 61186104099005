import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable ,  Subscription } from 'rxjs';
import { TitleCasePipe, CurrencyPipe } from '@angular/common';
import { CartService } from '../../../services/cart/cart.service';
import { ProductsService } from '../../../services/products/products.service';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { User } from '../../../models/user';
import { Offering } from '../../../models/offering';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./detail.component.css']
})

export class DetailComponent implements OnInit {

  selectedProduct;
  prods;

  subTotal = 0;
  displayCheckoutCard = false;
  displayCheckoutCardText = '';
  loginSubscription: Subscription;
  selectedProductSubscription : Subscription;
  loggedIn = false;
  public user$: Observable<User>;
  detailForm: UntypedFormGroup;
  offering: Offering;
  unitPrice: number;
  errorMessage: String;
  currentDate : number = Date.now();
  NOT_YET : number = -1;
  NOT_ANYMORE : number = 0;
  AVAILABLE : number = 1;

  constructor(
    public productService: ProductsService,
    public cartService: CartService,
    private route: ActivatedRoute,
    private authorizedService: AuthorizedService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => this.getProductDetail(params)
    );

    this.loginSubscription = this.authorizedService.getLoginStatus().subscribe(user => this.verifyUserLogin(user));
    this.selectedProductSubscription = this.productService.selectedProductSubject.subscribe(product => this.updateProductDetail(product));

  }


  verifyUserLogin(user: User) {
    if (user.username !== undefined) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

  }

  updateProductDetail(selectedProduct){
    this.selectedProduct = selectedProduct;
    if (this.selectedProduct === undefined) {
      this.errorMessage = 'The selected product was not found.';
     }
     else{

      this.detailForm = this.formBuilder.group(
        {
          //quantity: ['', [Validators.required,Validators.min(1)]],
          quantity: ['', [Validators.required, Validators.min(1), Validators.pattern(/^\d*$/)]],
          unit:[this.selectedProduct.offerings[0].id, Validators.required]
        }
      );
        this.detailForm.valueChanges.subscribe(val => {
          
        this.offering = this.selectedProduct.offerings.find(o => o.id === Number(val.unit));
        if(this.offering !=null){
           this.unitPrice = this.offering.unitPrice;
        }
          if (this.detailForm.valid === true) {
            this.subTotal = val.quantity * this.offering.unitPrice;
          }
        
        });
          this.unitPrice = this.selectedProduct.offerings[0].unitPrice;
     }
     this.displayCheckoutCard = false;
  }
  getProductDetail(params): void {
    this.selectedProduct = null;
    this.productService.getProductsByCategory(params.category)
    this.productService.getProductById(params.id);
  }

  addToCart(detailForm) {
    this.cartService.addToCart(parseInt(detailForm.value.quantity), detailForm.value.unit, this.selectedProduct);
    this.displayCheckoutCard = true;
    detailForm.get('quantity').reset();
  }

  openLargeImage(content,category) {
    //this.modalService.open(content,{size:'sm', windowClass:'custom-modal'});
    if(category.toLowerCase().includes('cigars')){
      this.modalService.open(content,{windowClass:'custom3'});
    }
    else if(category.toLowerCase().includes('proficiency')){
      this.modalService.open(content,{windowClass:'custom3'});
    }
    else{
      this.modalService.open(content,{size:'sm', windowClass:'custom-modal'});
    }
    
  }

  getProductAvailability(){
    var endDate = Date.parse(this.selectedProduct.endDate);
    var beginDate = Date.parse(this.selectedProduct.startDate);
    var now = Date.now();
    if(beginDate > now){
      return this.NOT_YET;
    }
    else if(endDate < now){
      return this.NOT_ANYMORE;
    }
    if(this.selectedProduct.category != 'Smoke Condensate'){
      return this.AVAILABLE;
    }
    return  -2; 
  }

  // get diagnostic() {
  //   console.log('diagnostic ', this.formObject) //, ' ', this.model ) 
  //   return JSON.stringify(this.formObject); 
  // }

}

