<div class="card" style="overflow-x: auto;">
    <div class="card-header bg-excel-header text-light" [style]="headerStyle" [innerHTML]="sectionData.sectionName">
      <!--{{sectionData.sectionName}}-->
    </div>
    <div class="card-block">
    <div class="row no-gutters" *ngFor="let data of sectionData.generalData">
      <div class="col-3 text-end">
        {{data.html}}:
      </div>
      <div class="col-3" style="margin-left:-10px" *ngIf ="data.html.toLowerCase().indexOf('date') > -1; else noDate">
        {{sectionData.tableData[0][data.name] | date:'shortDate':'GMT'}}
      </div>
      <ng-template #noDate>
          {{sectionData.tableData[0][data.name]}}
      </ng-template>
    </div>
  
<table class= "table">
  <thead>
    <tr style="border-bottom:1px solid black;">
      <th *ngFor="let header of sectionData.tableDisplay" class="bg-excel-column text-center">{{header.html}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor = "let row of sectionData.tableData">
      <td *ngFor = "let name of sectionData.tableDisplay" class="border-column text-center">
        {{row[name.name]}} <span *ngIf="row[name.name] == null">&lt;empty&gt;</span>
      </td>
    </tr>
    <tr *ngFor = "let row of sectionData.methodList; index as k"><!--row is an array containing each column of data-->
      <td *ngFor = "let data of sectionData.tableDisplay; index as i" style="text-align:center; vertical-align: middle;">
        <ng-container *ngFor ="let r of row; index as j">
          <!--the +2 is because the replicate field isn't displayed.  Also means that method list columns must be right after replicate column-->
          <ng-container *ngIf="r.cellColumn == data.cellColumn +2 && j == 0 && setHeaderFlag()">
            <span style="font-size:xx-small">{{r.html}}</span>
          </ng-container>
          
          <ng-container *ngIf="r.cellColumn == data.cellColumn && needsHeaders">
              <div style="font-size:xx-small">{{r.html}}</div>
          </ng-container>
          
          <ng-container *ngIf="isDate(r.html); else noDate">
            <ng-container *ngIf="r.cellColumn == data.cellColumn">
                {{r.value | date:'shortDate':'GMT'}}
            </ng-container>
          </ng-container>
          <ng-template #noDate>
            <ng-container *ngIf="r.cellColumn == data.cellColumn">{{r.value}}</ng-container>
          </ng-template>
          <!--<ng-container *ngIf="r.cellColumn == data.cellColumn">
            {{r.value}} 
          </ng-container>-->
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
</div>
</div>
