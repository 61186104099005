
<div class="row content" *ngIf="addressFormValid && answerFormValid">
    <div class="col-md-3 order-md-2 mx-auto">
        <button class="btn btn-primary btn-lg mt-0 ms-3 ps-5 pe-5 mb-1 text-white" (click)="confirmPurchase()">Place Order</button>
    </div>
  <div class="col-md-9 order-md-1">
    <div>
        
        <app-verifyorder [canEdit]="true"></app-verifyorder>
        <app-orderaddress [billingAddress]="cartService.getAddresses().billing" [shippingAddress]="cartService.getAddresses().shipping" [showAll]="true"></app-orderaddress>
        <app-responses [responses]="responses"></app-responses>
    </div>
  </div>
 
</div>


<div *ngIf="!showCart">
    <span class="bg-danger p-3 m-2 h5 text-white">You have not added any items to your shopping cart yet!</span>
    <a routerLink="/products/gallery/Reference%20Cigarettes" class="btn btn-primary btn-sm mt-0 ms-3 text-white">View Products</a>
  
</div>
<div *ngIf="showCart && !addressFormValid">
 Please return and complete the <a routerLink="/cart/addresses">Addresses</a> section.
</div>

<div *ngIf="showCart && !answerFormValid">
  Please return and complete the <a routerLink="/cart/questions">Questions</a> section.
</div>