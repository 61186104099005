import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProductsService } from '../services/products/products.service';
import { QaService } from '../services/qa/qa.service';

@Injectable()
export class AppLoadService {

  constructor(
    private httpClient: HttpClient,
    private productsService: ProductsService,
    private qaService: QaService
  ) { }

  // Products
  getProducts(): Promise<any> {

    const promise = this.httpClient.get('/refcig-services/products')
      .toPromise()
      .then(productData => {
        this.productsService.setProducts(productData);
        // this.menuService.createMenuItems();

      });

    return promise;
  }

  // Questions
  getQA(): Promise<any> {

    const promise = this.httpClient.get('/refcig-services/questions')
      .toPromise()
      .then(qaData => {
        this.qaService.setQA(qaData);
      });

    return promise;
  }

}
