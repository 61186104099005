import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-sectiondata',
  templateUrl: './sectiondata.component.html',
  styleUrls: ['./sectiondata.component.css']
})
export class SectiondataComponent implements OnInit {
  private needsHeaders : Boolean;
  @Input('sectionData') sectionData : any;
  public headerStyle:String;
  constructor() { 
    this.needsHeaders = true;
  }

  ngOnInit() {
    //debugger;
    if(this.sectionData.sectionName.includes("class")){
      var start = this.sectionData.sectionName.indexOf("=");
      var end = this.sectionData.sectionName.indexOf(">");
      var color = this.sectionData.sectionName.substring(start + 2, end-1);
      this.headerStyle="background-color:" + color+" !important;";
    }    
    
  }

  isDate(htmlString : String):Boolean{
    if(htmlString.toLowerCase().includes('date')){
      return true;
    }
    return false;
  }
  setHeaderFlag():Boolean{
    //If methods are offset from columns as in the case of the cigarette proficiency studies set the needsHeaders flag
    //to false so that headers won't also appear over top of the columns.
    this.needsHeaders = false;
    return true;
  }
}
 