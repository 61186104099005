import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

//import { Cart } from '../../../models/cart';
import { Addresses } from '../../../models/addresses';
import { CartService } from '../../../services/cart/cart.service';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { CountryList } from '../../../models/countryList';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent{

  cartItems;
  cart;
  addresses = new Addresses();
  addressesForm: UntypedFormGroup;
  addressFormSubscription : Subscription;
  previousAddresses = Array<any>();
  hasBillingAddresses : boolean = false;
  countryList = new CountryList();
  buttonText = 'Copy Shipping Address into Billing?';

  constructor(
    public cartService: CartService,
    private router: Router,
    private authorizedService : AuthorizedService
  ) { 
    this.cartService.addressFormSubject.subscribe(form => this.setForm(form));
    this.authorizedService.getLoginStatus().subscribe(res => this.setPreviousAddresses(res));
    
  }
  combo(thelist, theinput) {
    //console.log('asdfasdf');
    //theinput = document.getElementById(theinput);
    //var idx = thelist.selectedIndex;
    //var content = thelist.options[idx].innerHTML;
    //theinput.value = content;
}
  setPreviousAddresses(res){
    this.previousAddresses = res['addresses'];
    //check for any billing only addresses
    if(this.previousAddresses){
      let i = 0;
      this.hasBillingAddresses = false;
      while(i < this.previousAddresses.length && !this.hasBillingAddresses){
        if(res.addresses[i].addressType == 'billing'){
          this.hasBillingAddresses = true;
        }
        i++;
      }
    }

  }
  previousShippingAddressChecked(index){
    /* On focus is used in case the user selects an address, then makes manual changes but then wants to re-populate by selecting the same address.
    change event doesn't fire unless user selects different address.
    */
    if(index != ""){
      this.addressesForm.patchValue(
        {
          shipping: {
            id : this.previousAddresses[index].id,
            firstname: this.previousAddresses[index].firstname,
            lastname: this.previousAddresses[index].lastname,
            institution: this.previousAddresses[index].institution,
            email: this.previousAddresses[index].email,
            address: this.previousAddresses[index].address,
            address2: this.previousAddresses[index].address2,
            city: this.previousAddresses[index].city,
            state: this.previousAddresses[index].state,
            zip: this.previousAddresses[index].zip,
            country: this.previousAddresses[index].country,
            phone: this.previousAddresses[index].phone,
            fax: this.previousAddresses[index].fax
          }
        });
    }

    
  }
  previousBillingAddressChecked(index){
    if(index != ""){
      this.addressesForm.patchValue(
        {
          billing: {
            id : this.previousAddresses[index].id,
            firstname: this.previousAddresses[index].firstname,
            lastname: this.previousAddresses[index].lastname,
            institution: this.previousAddresses[index].institution,
            email: this.previousAddresses[index].email,
            address: this.previousAddresses[index].address,
            address2: this.previousAddresses[index].address2,
            city: this.previousAddresses[index].city,
            state: this.previousAddresses[index].state,
            zip: this.previousAddresses[index].zip,
            country: this.previousAddresses[index].country,
            phone: this.previousAddresses[index].phone,
            fax: this.previousAddresses[index].fax
          }
        });
    }
    //var index = event.target.value;
    
  }

  setForm(addressForm){
    this.addressesForm = addressForm;

  }


  addToCart(addresses: Addresses) {  }

  useShippingAddressForBilling() {

    if (this.cartService.shippingValid === true) {

      this.addressesForm.patchValue(
        {
          billing: {
            id : this.addressesForm.get('shipping.id').value,
            firstname: this.addressesForm.get('shipping.firstname').value,
            lastname: this.addressesForm.get('shipping.lastname').value,
            institution: this.addressesForm.get('shipping.institution').value,
            email: this.addressesForm.get('shipping.email').value,
            address: this.addressesForm.get('shipping.address').value,
            address2: this.addressesForm.get('shipping.address2').value,
            city: this.addressesForm.get('shipping.city').value,
            state: this.addressesForm.get('shipping.state').value,
            zip: this.addressesForm.get('shipping.zip').value,
            country: this.addressesForm.get('shipping.country').value,
            phone: this.addressesForm.get('shipping.phone').value,
            fax: this.addressesForm.get('shipping.fax').value
          }
        }
      );

    } 

  }

  onSubmit() {

    this.router.navigate(['cart/questions/']);

  }

}
