import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class FileService {

  constructor(private http: HttpClient) {

  }

  public getFile(URL : string, errorCallBack, thisReference){
    let headers = new HttpHeaders({ 
      'Accept': 'application/octect-stream'
   });


    var request = this.http.get(URL, {headers, responseType:'arraybuffer',observe:'response'});
    request.subscribe(response => this.downloadFile(response),
      error => { let f = errorCallBack.bind(thisReference); f(error)});
  }

  private downloadFile(data){//chome and firefox
    var fileName = data.headers.get('content-disposition').split("=")[1];
    fileName = fileName.slice(1,fileName.length -1);//removeing quotes
    var b = new Blob([new Uint8Array(data.body)]);
    //if(navigator.msSaveOrOpenBlob ==null){
      this.saveData(b,fileName);
    //}
    //else{//internet explorer
     // navigator.msSaveOrOpenBlob(b,fileName);
    //}
  }
  public postJSON(endpointURL, jsondata, successCallback = null, errorCallback = null, thisReference = null){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    var request = this.http.post(endpointURL,jsondata, httpOptions);
    if(successCallback != null){
      successCallback = successCallback.bind(thisReference)
      if(errorCallback !=null){
        errorCallback = errorCallback.bind(thisReference);
        request.subscribe((res) => successCallback(res), (error) => errorCallback(error));
      }
      else{
        request.subscribe((res) => successCallback(res));
      }
    }
  }
  
  public postFile(endpointURL, formdata, successCallback = null, errorCallback = null, thisReference = null){
    
    var request = this.http.post(endpointURL,formdata);
    if(successCallback != null){
      successCallback = successCallback.bind(thisReference)
      if(errorCallback !=null){
        errorCallback = errorCallback.bind(thisReference);
        request.subscribe((res) => successCallback(res), (error) => errorCallback(error));
      }
      else{
        request.subscribe((res) => successCallback(res));
      }
    }

  }

  private saveData(blob, fileName){
      var a = document.createElement("a");
      document.body.appendChild(a);
      //a.style = "display: none";
      var url = window.URL.createObjectURL(blob); 
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      }

}