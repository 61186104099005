<h3 class="card-header full-card ps-1">Orders</h3>
<div class="row d-print-none" style="overflow-x: auto; max-height: 300px;">
  <div [hidden]="selectedOrderId != null && orders !=null"><strong>Select an order from table below to view details</strong></div><br />
  <table class="table table-striped table-hover table-james" *ngIf="orders !=null && orders.length > 0 else noOrders">
        <thead>
          <tr>
            <th>Order Id</th>
            <th>Order Date</th>
            <th>Subtotal</th>
            <th>Ship Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of orders" [ngClass]="selectClass(o.id)" (click)="orderSelected(o, $event)">
            <td>{{o.id}}</td>
            <td>{{o.purchaseDate | date:'shortDate':'GMT'}} </td>
            <td>{{o.total | currency:'USD'}}</td>
            <td>{{o.shippedDate | date:'shortDate':'GMT'}}</td>
            <td>{{o.status.description}}</td>
          </tr>
        </tbody>
      </table>
      <ng-template #noOrders>
        <p>You don't have any orders yet!</p>
      </ng-template>
    </div>

    <div class="d-print-block d-none" *ngIf="purchase !=null">
        <h3 class="text-center">Order Summary - {{purchase.id}}</h3>
        
        <div class="row">
            <div class="col-9">
                <ul>
                <li><strong>Order Date: </strong>{{purchase.purchaseDate | date:'shortDate'}}</li>
                <li><strong>Order Id: </strong>{{purchase.id}}</li>
                <li><strong>P.O. number: </strong>{{purchase.poNumber}}</li>
                <li><strong>Placed By: </strong>{{purchase.userDisplayName}}</li>
                <li><strong>Shipping Method: </strong>{{purchase.shippingMethod}}</li>
                <li><strong>Tracking Number: </strong>{{purchase.trackingNumber}}</li>
                <li><strong>Lot Numbers: </strong>{{purchase.lotNumbers}}</li>
                </ul>
            </div>
            <div class="col-3"><img src="../../assets/img/ag_logo_highres.png" height="180px"></div>
        </div>
    </div>
  
<router-outlet></router-outlet>