import {Product} from './product';


export class Round{
    id : number;
    notes : string;
    name : string;
    commentsEndDate : Date;
    endDate : Date;
    submitPortalStartDate : Date;
    profStudyReferenceId : string;
    machArch : string;
    product : Product;
    report : number;
    protocol : number;
    template : number;
    interimReport : number;
    roundStatus : number;
    roundText : string;

    constructor(){
        this.id = -1;
        this.notes = "";
        this.name = "";
        this.commentsEndDate = null;
        this.endDate = null;
        this.submitPortalStartDate = null;
        this.profStudyReferenceId = null;
        this.machArch = null;
        this.product = null;
        this.report = null;
        this.protocol = null;
        this.template = null;
        this.interimReport = null;
    }
    /*constructor(json:any){
        this.id = json.id;
        this.notes = json.notes;
        this.name = json.name;
        this.beginDate = new Date(json.beginDate);
        this.endDate = new Date(json.endDate);
        this.submitPortalStartDate = new Date(json.submitPortalStartDate);
        this.profStudyReferenceId = json.profStudyReferenceId;
        this.machArch = json.machArch;
        this.product = new Product(json.product);
        this.report = json.report;
        this.protocol = json.protocol;
        this.template = json.template;
        this.interimReport = json.interimReport;
    }*/
}