<h2>Announcements</h2>
<hr>
<h3>University of Kentucky, Center for Tobacco Reference Products-FDA Cooperative Agreement Workshop</h3>
<p>The Center for Tobacco Reference Products (CTRP) at the University of Kentucky will host a workshop starting at 3:00pm on August 29th prior to the start of the 74th Tobacco Science Research Conference.</p>

<p>The current statistical approach based on using consensus values for scoring in the proficiency testing program will be discussed.  The impact of small sample sizes on scoring of proficiency testing results will be evaluated and an open discussion will be encouraged.</p>

<p>CTRP will discuss plans and solicit feedback regarding the proficiency testing program of the certified reference tobacco products.  CTRP will review the results for a variety of HPHCs in mainstream smoke proficiency testing rounds using the 1R6F reference cigarette.  Additionally, the results from the first round of proficiency testing using the four reference smokeless tobacco products will be discussed.  We seek feedback from all participants regarding the addition of more compounds of interest to our future proficiency testing schemes.</p>

<p>There will be exhibit tables in the conference room displaying all current CTRP reference products for participants 
  to review.  CTRP personnel will be available to answer questions regarding the products before and during the 
  workshop. The tobacco reference products and information about the chemical constituents are available on the 
  <a href="/products/gallery/Reference%20Cigarettes">products</a> page.  </p>


<p>This workshop is meant to engage stakeholders to obtain feedback on tobacco reference products and programs being initiated within the CTRP with support from the FDA’s Center for Tobacco Products and the industry.  </p>
<p><strong>There is no cost to attend the workshop, but we request that you send an e-mail to <a href="mailto:ctrp&#64;uky.edu">ctrp&#64;uky.edu</a> and register for planning purposes.</strong></p>