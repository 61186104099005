<div *ngIf="news.length > 0" class="card mb-1">
    <div *ngIf="!closure; else closingNotice" class="card-header bg-primary text-white">
        News Update {{news[0].displayStart | date:'shortDate'}}
      </div>
      <ng-template #closingNotice>
        <div class="card-header text-white" style="background-color:red;">
          News Update {{news[0].displayStart | date:'shortDate'}} CLOSURE NOTICE
        </div> 
      </ng-template>
      <div class="card-body news">
        <span *ngFor="let n of news" [innerHTML]="n.message"></span>
      </div>
</div>
