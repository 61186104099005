import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appInteger]',
  providers: [{provide: NG_VALIDATORS, useExisting: IntegerDirective, multi: true}]
})


export class IntegerDirective implements Validator{

  constructor() { }
  @Input('appInteger') myint = '';

  validate(control: AbstractControl): ValidationErrors | null {
    //debugger;
    return this.myint ? integerValidator(new RegExp(this.myint, 'g'))(control): null;
  }

}
  
  export function integerValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isInt = nameRe.test(control.value);
      //debugger;
      return isInt ? null : {myint: {value: control.value}};
    };
  }
