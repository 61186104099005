import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizedService } from '../../services/authorized/authorized.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CartService } from '../../services/cart/cart.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  loginSubscription: Subscription;
  loggedIn: boolean;
  isActive:boolean;
  constructor(private authorizedService:AuthorizedService, private router:Router, public cartService:CartService) { }

  ngOnInit() {
    this.loggedIn = false;
    this.loginSubscription = this.authorizedService.getLoginStatus().subscribe(user => this.loginStatusChanged(user));
    this.isActive =false;
    this.router.events.subscribe( (event: Event) => {


      if (event instanceof NavigationEnd) {
          this.resetNav();
          if(event.url.includes('/home')){
            document.getElementById('navHome').classList.add('activeTab');
          }
          else if(event.url.includes('/products')){
            document.getElementById('navProducts').classList.add('activeTab');
          }
          else if(event.url.includes('/orders')){
            document.getElementById('navOrders').classList.add('activeTab');
          }
          else if(event.url.includes('/cart')){
            document.getElementById('navCart').classList.add('activeTab');
          }
          else if(event.url.includes('/profstudies')){
            document.getElementById('navProfstudies').classList.add('activeTab');
          }
      }
  });

}

  loginStatusChanged(user){
		if(user.username){
      this.loggedIn = true;
		}
		else{
			this.loggedIn = false;
		}
  }

  resetNav(){
    var items = Array.from(document.getElementsByClassName('nav-item'));
    for (let i of items){
      i.classList.remove('activeTab');
    }
  }
toggleNav(){
  this.isActive = !this.isActive;
}

}

