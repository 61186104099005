import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tsrc',
  templateUrl: './tsrc.component.html',
  styleUrls: ['./tsrc.component.scss']
})
export class TsrcComponent implements OnInit {
  public isCollapsed = true;
  public is2022Collapsed = true;
  constructor() { }

  ngOnInit(): void {
  }

}
