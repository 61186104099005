<form (ngSubmit)="onSubmit()" #loginForm="ngForm" [hidden]="loggedIn">
  <div class="form-group">
    <input type="text" class="form-control" placeholder="Username" required [(ngModel)]="model.username" name="username" #username='ngModel'>
    <input type="password" class="form-control" placeholder="Password" required [(ngModel)]="model.password" name="password" #userpass='ngModel'>
	</div>
  <div class="d-flex justify-content-between align-items-center mt-2">
    <button type="submit" class="btn btn-primary text-white" [disabled] = "!loginForm.form.valid">Submit</button>
    <a [hidden]="loggedIn" class="text-white text-decoration-none" href="/pwm/public/forgottenpassword">Forgot your password?</a>
  </div>
</form>


<div [hidden]="!loggedIn">
	<p class="text-white">Logged in as {{userInfo.username}}</p>
	<button type="button" (click)="onLogoutClick()" class="btn btn-primary text-white">log out</button> 
</div>
