import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../../../services/products/products.service';
import { Product } from '../../../models/product';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})

export class GalleryComponent implements OnInit {

  products;
  prods;
  selectedProduct = Product;
  certifiedState:number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public productsService: ProductsService

  ) { 
    this.productsService.selectedCategorySubject.subscribe(products => this.setProducts(products))
  }

  ngOnInit() {

    this.route.params.subscribe(
      params => this.getProducts(params.id)
    );
  }

  setProducts(products){
    this.certifiedState = this.productsService.certifiedState;
    this.products = products;
  }
  getProducts(category) {
    this.productsService.activeProduct = null;
    this.productsService.getProductsByCategory(decodeURI(category));
  } 

  onSelect(product) {
    this.selectedProduct = product;
    this.router.navigateByUrl('products/gallery/'+product.category+'/detail/' + product.id);
  }

}
