<div class="modal-header">
  Submit Comments
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">
  </button>
</div>
<div class="modal-body" style="height:400px; overflow:auto;" id="commentBox">
<form [formGroup]="commentsForm">
  <div formArrayName="commentArray">
      <div *ngFor="let c of commentArray.controls; let i=index" [formGroupName]="i">
          <div class="form-group">
              <label>Page:
                  <input class="form-control" type="number" min="1" formControlName="page">
                </label>
          </div>
          <div class="form-group">
              <label>Paragraph:
                  <input class="form-control" type="number" min="1" formControlName="paragraph">
                </label>
          </div>
          <div class="form-group">
              <label>Table/Figure:
                  <input class="form-control" type="text" formControlName="tableFigure">
                </label>
          </div>
          <div class="form-group">
              <label>Comment:
                  <textarea cols="35" rows="4" class="form-control" type="text" formControlName="comment"></textarea>
                </label>
          </div>
          <hr *ngIf="commentArray.length > 1">
        </div>
  </div>
  
</form>
</div>
<div class="modal-footer">
    <button type="button" (click)="addAnotherComment()" class="btn btn-primary text-white">Add Another</button>
    <button type="button" (click)="submitComments()" class="btn btn-primary text-white">Submit</button>
   <!-- <p>{{ commentsForm.value | json }}-->
</div>
