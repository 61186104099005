export class Addresses {

    shipping: {
        shippingFirstname: '';
        shippingLastname: '';
        shippingInstitution: '';
        shippingEmail: '';
        shippingAddress: '';
        shippingAddress2: '';
        shippingCity: '';
        shippingState: '';
        shippingZip: '';
        shippingCountry: '';
        shippingPhone: '';
        shippingFax: '';
        shippingInstructions: '';
        shippingPo: '';
        shippingTaxid: '';
    };

    billing: {
        billingFirstname: '';
        billingLastname: '';
        billingInstitution: '';
        billingEmail: '';
        billingAddress: '';
        billingAddress2: '';
        billingCity: '';
        billingState: '';
        billingZip: '';
        billingCountry: '';
        billingPhone: '';
        billingFax: '';
    };

    constructor() { }
}
