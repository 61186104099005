import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { AuthorizedService } from '../../services/authorized/authorized.service'
import { OrdersService } from '../../services/orders/orders.service';
import { Navigation } from 'selenium-webdriver';
import { Purchase } from '../../models/purchase';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {
  orders : any = [];
  ordersSubscription : Subscription;
  selectedOrder : Subscription;
  selectedOrderId : number;
  paramSub : Subscription;
  purchase : Purchase;

  constructor(private authorizedService:AuthorizedService, private router: Router, private activatedRoute : ActivatedRoute, private orderService : OrdersService) {
    this.ordersSubscription = this.authorizedService.orderSubject.subscribe(orders => this.setOrders(orders));
    this.selectedOrder = this.orderService.purchaseSubject.subscribe(id => this.setOrder(id));

   router.events.subscribe((val) => {
    //in the event that a user selects an order then navigates back to /orders the last selected order will still have a different background this will reset it
    if(val instanceof NavigationEnd){
      if(val.url == '/orders'){
        this.selectedOrderId = null;
      }
    }
  }); 
  }

  setOrders(orders){
    this.orders = orders;
    if(this.selectedOrderId !=null){
      this.setPurchase(this.findOrderById(this.selectedOrderId));
    }
  }
  orderSelected(order, $event){
    this.selectedOrderId = order.id;
    this.router.navigate(['./',order.id],{relativeTo:this.activatedRoute});
    this.setPurchase(order);
  }

  setPurchase(order){
    this.purchase = order;
  }
  setOrder(id){
    this.selectedOrderId = id;
    this.setPurchase(this.findOrderById(id));
  }
  selectClass(orderId){
    if(orderId == this.selectedOrderId){
      return 'ordersTableRow orderSelectedRow';
    }
    return 'ordersTableRow';
  }
  findOrderById(id){
    let found = false;
    let i = 0;
    while(!found && i < this.orders.length){
      if(this.orders[i].id == id){
        found = true;
        return this.orders[i];
      }
      i++
    }
  }
}
 