<h3 class="card-header full-card ps-1 d-print-none">Order Details: {{orderId}}</h3>
<div class="row" style="overflow-x: auto;">
<table class="table" [hidden]="details == null || billing == null">
    <thead>
      <tr>
        <th>Product Name</th>
        <th>Price Per Unit</th>
        <th>Quantity Ordered</th>
        <th>Quantity Per Unit</th>
        <th>Total Product Cost</th>
      </tr>
    </thead>
    <tbody *ngIf="details !=null">
      <tr *ngFor="let d of details">
        <td>{{d.offering.product.name}}</td>
        <td>{{d.pricePerUnit | currency:'USD'}}</td>
        <td>{{d.quantityOrdered}}</td>
        <ng-container *ngIf="d.offering.unit == 'bottle'; then bottle; else nobottle"></ng-container>
          <ng-template #bottle>
            <td>{{d.offering.unitBase}}</td>
          </ng-template>
          <ng-template #nobottle>
              <ng-container *ngIf='d.quantityOrdered > 1 else elseBlock'><td>{{d.offering.unitCount | currency:'':'':'1.0-0'}} ({{d.offering.unitBasePlural}})</td></ng-container>
              <ng-template #elseBlock><td>{{d.offering.unitCount | currency:'':'':'1.0-0'}} ({{d.offering.unitBase}})</td></ng-template>
          </ng-template>
        
        <td>{{d.subtotal | currency:'USD'}}</td>
      </tr>
      <tr>
        <td colspan="3"></td>
        <td class="text-right">Product Total</td>
        <td>{{productTotal | currency:'USD'}}</td>
      </tr>
      <tr>
        <td colspan="3"></td>
        <td class="text-right">Shipping</td>
        <td *ngIf='shippingCharge !=null else noShipping'>{{shippingCharge | currency:'USD'}}</td>
        <ng-template #noShipping><td>Charges to be determined</td></ng-template>
      </tr>
      <tr *ngFor='let c of otherCharges'>
        <td colspan="3"></td>
        <td class="text-right">{{c.billingtype.description}}</td>
        <td>{{c.amount | currency:'USD'}}</td>
      </tr>
      <tr *ngFor='let p of payments'>
        <td colspan="3"></td>
        <td class="text-right">{{p.billingtype.description}}</td>
        <td>{{p.amount * -1 | currency:'USD'}}</td>
      </tr>
      <tr>
        <td><button class="btn btn-primary d-print-none text-white" (click)="printOrderSummary()">Print Order Summary</button></td>
        <td colspan="2"></td>
        <td class="text-right">Balance Due</td>
        <td>{{balanceDue | currency:'USD'}}</td>
      </tr> 
    </tbody>
</table>
</div>