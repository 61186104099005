import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-orderbalancesheet',
  templateUrl: './orderbalancesheet.component.html',
  styleUrls: ['./orderbalancesheet.component.css']
})
export class OrderbalancesheetComponent {

  @Input() details : any;
  @Input() billing : any;
  @Input() otherCharges : Array<any>;
  @Input() payments : Array<any>;
  @Input() shippingCharge : number;
  @Input() balanceDue : number;
  @Input() productTotal : number;
  @Input() orderId : number;
  @Output() createPrinterFriendly = new EventEmitter<boolean>();
  constructor() { }

  printOrderSummary(){
    this.createPrinterFriendly.emit(true);
  }
}
