import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';

import { News } from '../../models/news';

@Injectable()
export class NewsService {

  private newsUrl = 'refcig-services/news';//url to web api for news

  constructor(private http: HttpClient ) { }

  getNews(): Observable<News[]>{

    return this.http.get<News[]>(this.newsUrl);

  }

}
