<h3 class="card-header full-card ps-1">Questions<span style="float:right; cursor: pointer;" (click)="toggle()">{{toggleIcon[toggleView === true ? 1 : 0]}}</span></h3>

<div class="card-body" [hidden]="!this.toggleView">


  
  <ng-container *ngFor="let r of responses">
  
    <h4 class="card-header ps-1">{{r[0].product.name}}</h4>
    <div class="ms-3">
    <h5>How do you plan to use this product?</h5>
    <ng-container *ngFor="let rr of r">
      <p *ngIf="rr.question.questionText == 'How do you plan to use this product?' ">{{rr.answerValue}}</p>
    </ng-container>
    <h5>When do you plan to use this product?</h5>
    <ng-container *ngFor="let rr of r">
      <p *ngIf="rr.question.questionText == 'When do you plan to use this product?'">{{rr.answerValue}}</p>
    </ng-container>
    <h5>How do you plan to dispose of any unused portion of this product?</h5>
    <ng-container *ngFor="let rr of r">
      <p *ngIf="rr.question.questionText == 'How do you plan to dispose of any unused portion of this product?'">{{rr.answerValue}}</p>
    </ng-container>
  </div>
  </ng-container>

</div> 