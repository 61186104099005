import { Injectable } from '@angular/core';
import { AuthorizedService } from '../authorized/authorized.service';
import { Subject } from 'rxjs';

@Injectable()
export class OrdersService {

  
  purchaseId : number;
  public purchaseSubject = new Subject<number>();

  constructor(private authorizedService:AuthorizedService) {}  


requestPurchaseDetails(purchaseId){

      this.purchaseId = purchaseId;
      this.authorizedService.getBilling(purchaseId);
      this.authorizedService.getDetails(purchaseId);
      this.authorizedService.getOrderCtrpfiles(purchaseId);
      this.purchaseSubject.next(purchaseId);

}
getCtrpfiles(){
  //this needs to be separate because the document manager needs to refresh the document list in case of addition/deletion of a file
  this.authorizedService.getOrderCtrpfiles(this.purchaseId);
}

}
