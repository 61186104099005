import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-physicaldata',
  templateUrl: './physicaldata.component.html',
  styleUrls: ['./physicaldata.component.scss']
})


export class PhysicaldataComponent implements OnInit {
  @Input('physicalData') physicalData : any;
  @Input('sectionHeader') sectionHeader : any;
  public headerStyle: String ="";
  constructor() { }

  ngOnInit() {
    if(this.sectionHeader.includes("class")){
      var start = this.sectionHeader.indexOf("=");
      var end = this.sectionHeader.indexOf(">");
      var color = this.sectionHeader.substring(start + 2, end-1);
      this.headerStyle="background-color:" + color+" !important;";
    }  
  }

}
 