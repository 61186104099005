
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppLoadService } from './app-load.service';


// Products
//export function get_products(appLoadService: AppLoadService) {
//  return () => appLoadService.getProducts();
//}

// Questions
export function get_qa(appLoadService: AppLoadService) {
  return () => appLoadService.getQA()
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppLoadService,
    //{ provide: APP_INITIALIZER, useFactory: get_products, deps: [AppLoadService], multi: true },
    { provide: APP_INITIALIZER, useFactory: get_qa, deps: [AppLoadService], multi: true }
  ]
})
export class AppLoadModule { }