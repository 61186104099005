import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-orderaddress',
  templateUrl: './orderaddress.component.html',
  styleUrls: ['./orderaddress.component.css']
})
export class OrderAddressComponent implements OnInit {
  @Input() shippingAddress : any;
  @Input() billingAddress : any;
@Input() showAll : boolean = false;
  constructor() {

  }

  ngOnInit() {
  }


}
