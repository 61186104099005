
<div class="modal-header">
    <h4 class="modal-title">Upload File</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
    </button>
</div>
<form (ngSubmit)="onSubmit()" #fileForm="ngForm">
  <div class="modal-body">
    <input type="file" name="file" (change)="handleFileInput($event.target.files)">
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-outline-dark" [disabled]="!isValid" >Submit</button>
  </div>
</form>
