
<div class="container-fluid" *ngIf="selectedProfstudies != null">
  <div class="row">
    <div class="col">
      <h3 ng-show="isLoaded" class="text-center">
        {{selectedProfstudies.roundName}}
      </h3>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    <h5 [hidden]="roundStatus == null || !found">{{roundStatus}}</h5>
    <h5 [hidden]="found">You haven't purchased any studies for this round. ()</h5>
  </div>
</div>
<hr class="mb-0">
<div class="row ms-1 me-1 bg-light h5" [hidden]="!found">
  <div class="col my-auto">Available Documents:</div>
  <div class="col">
      <ul class="my-auto">
          <li>
              <a *ngIf="browserIsIE == false" class="downloadLink" target="_blank" href="{{createLink('protocol')}}" download="Protocol_{{selectedProfstudies.roundName}}.pdf">Protocol</a>
              <a *ngIf="browserIsIE == true" class="downloadLink" (click)="createLink('protocol')">Protocol</a>
          </li>
          <li [hidden]="selectedProfstudies.purchasedStudies[0].round.interimReport == null && selectedProfstudies.purchasedStudies[0].round.report == null">
            <a *ngIf="browserIsIE == false" class="downloadLink" target="_blank" href="{{createLink('interim')}}" download="Interim_Report_{{selectedProfstudies.roundName}}.pdf">Interim Report</a>
            <a *ngIf="browserIsIE == true" class="downloadLink" (click)="createLink('interim')">Interim Report</a>
          </li>
          <li [hidden]="selectedProfstudies.purchasedStudies[0].round.report == null">
            <a *ngIf="browserIsIE == false" class="downloadLink" target="_blank" href="{{createLink('final')}}" download="Final_Report_{{selectedProfstudies.roundName}}.pdf">Final Report</a>
            <a *ngIf="browserIsIE == true" class="downloadLink" (click)="createLink('final')">Final Report</a>
          </li>
      </ul>
    </div>

</div>

</div>


<div class="container-fluid">
  <div class="row" *ngFor="let type of studyTypes">
    <div class="col">
      <span *ngIf="type != null">Machine Type: {{type | toUppercase}}</span>
    <div class="row">
      <div class="col">
        <table class="table table-stripped">
          <thead>
            <tr>
              <th scope="col">Data Id</th>
              <th scope="col " class="d-none d-sm-table-cell">Purchaser</th>
              <th scope="col">Template</th>
              <th scope="col">Action</th>
              <th scope="col">Data Status</th>
            </tr>
          </thead>
          
          <tbody>
            <tr  *ngFor="let study of studies[type]">
              <td>{{study.id}}</td>
              <td class="d-none d-sm-table-cell">{{study.purchaser}}</td>
              <td>
                <a *ngIf="browserIsIE == false" class="downloadLink" target="_blank" href="/refcig-services/profstudytemplate/{{study.id}}" download="Linear_{{selectedProfstudies.roundName}}_Template.xlsx">Download Template</a>
                <a *ngIf="browserIsIE == true" class="downloadLink" (click)="authorizedService.getFile('refcig-services/profstudytemplate/'+study.id)">Download Template</a>
              </td>
              <td>
                <ng-container *ngIf="study.status == null; then studyNull; else studyNotNull"></ng-container>
                <ng-template #studyNotNull>
                    <button type="button" class="btn btn-primary me-2 mb-1 text-white" *ngIf="study.status.includes('resubmit') && (roundStatus.includes('Final') || roundStatus.includes('comments') || roundStatus.includes('Open Until') == true)" (click)="openModal(study)">Resubmit Data</button>
                    <!--<button type="button" class="btn btn-primary me-2 mb-1" *ngIf="study.status.includes('resubmit')" (click)="openModal(study)">Resubmit Data</button>-->
                    <button type="button" class="btn btn-primary me-2 text-white" *ngIf="roundStatus.includes('comments')" (click)="openComments(study)">Submit Comments <br /></button>
                    <span *ngIf="roundStatus.includes('Opens on')" style="font-size:small">Submission Portal not open yet</span>
                    <span *ngIf="(roundStatus.includes('Waiting') || roundStatus.includes('Final')) && study.status != 'complete'" style="font-size:small">Submission Portal closed</span>
                    <span *ngIf="roundStatus.includes('Final Report Available') && !study.status.includes('resubmit')" style="font-size:small">No action available</span>
                </ng-template>
                <ng-template #studyNull>
                    <button type="button" class="btn btn-primary me-2 text-white" *ngIf="roundStatus.includes('Open Until') == true" (click)="openModal(study)">Submit Data</button>
                    <span *ngIf="roundStatus.includes('Final Report Available')" style="font-size:small">No action available</span>
                </ng-template>

              </td>
              <td>
                  <ng-container *ngIf="study.status == null; then studyNull2; else studyNotNull2"></ng-container>
                  <ng-template #studyNotNull2>
                      <a *ngIf="browserIsIE == false && (study.status.includes('complete') || study.status.includes('resubmit'))" class="downloadLink" target="_blank" href="{{createLink('submittedData',study.id)}}" download="{{selectedProfstudies.roundName}}.xlsx">Download Submitted Data</a>
                      <a *ngIf="browserIsIE == true && (study.status.includes('complete') || study.status.includes('resubmit'))" class="downloadLink" (click)="createLink('submittedData', study.id)">Download Submitted Data</a>
                  </ng-template>
                  <ng-template #studyNull2>
                      <p style="font-size:small">No data submitted</p>
                  </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
</div>


