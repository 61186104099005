import { Injectable } from '@angular/core';
import { Cart } from '../../models/cart';
import { CartItem } from '../../models/cartitem';
import { QaService } from '../../services/qa/qa.service';
import { Offering } from '../../models/offering';
import { ReplaySubject} from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray, ReactiveFormsModule } from '@angular/forms';
import { AuthorizedService } from '../authorized/authorized.service';
import { QA } from '../../models/qa';
import { CountryList } from '../../models/countryList';

@Injectable()
export class CartService {

  cart = new Cart();
  offering: Offering;
  public questionFormSubject = new ReplaySubject<any>();
  public addressFormSubject = new ReplaySubject<any>();
  public answerForm: UntypedFormGroup;
  public addressesForm: UntypedFormGroup;
  public subtotal;
  public shippingValid:boolean = false;//indicates if the shipping address form is valid
  public addressesFormValid:boolean = false;
  public questionsList : Array<any> = new Array<any>();

  constructor(public qaService: QaService, 
              private formBuilder: UntypedFormBuilder, 
              private authorizedService : AuthorizedService) { 
    
    this.createAddressForm();
    this.authorizedService.getLoginStatus().subscribe(user => this.loginStatusChanged(user))
  }

  loginStatusChanged(user){
    if(user.username == null){
      this.resetCart();
    }
  }
  addToCart(quantity: number, offeringId: number, selectedProduct: any) {
    this.offering = selectedProduct.offerings.find(o => o.id === Number(offeringId));
    var updateQuestions = false;
    var offeringIndex = this.cart.hasOffering(this.offering.id);
    if(offeringIndex < 0){//offering not found, adding a new offering to cart
      const unitPrice = this.offering.unitPrice;
      const subtotal: number = quantity * unitPrice;
      const cartItem = new CartItem();
      const qaObj = JSON.parse(JSON.stringify(this.qaService.getQa()));
      cartItem.productId = selectedProduct.id;
      cartItem.productName = selectedProduct.name;
      cartItem.offeringId = this.offering.id;
      cartItem.unit = this.offering.unit;
      cartItem.unitPlural = this.offering.unitPlural;
      cartItem.unitPrice = this.offering.unitPrice;
      cartItem.quantity = quantity;
      cartItem.subtotal = subtotal;
      cartItem.image = selectedProduct.image;
      cartItem.category = selectedProduct.category; 
      cartItem.qa = qaObj;
      cartItem.unitCount = this.offering.unitCount;
      cartItem.unitBase = this.offering.unitBasePlural;
      console.log(cartItem.unitCount * cartItem.quantity);

      if(this.cart.hasProduct(selectedProduct.id) < 0){
        this.questionsList.push(cartItem);
        updateQuestions = true;
      }
      this.cart.addProduct(cartItem);
      if(updateQuestions){
        this.createQuestionForm();
        this.questionFormSubject.next(this.answerForm);
      }
      this.updateCartInfo();
  }
  else{//cart already has offering of same product must add to current cart items
    this.cart.CartItems[offeringIndex].subtotal += quantity * this.offering.unitPrice;
    this.cart.CartItems[offeringIndex].quantity += quantity;
    this.updateCartInfo();
  }

  }

  getCart(): Cart {
    return this.cart;
  }

  getCartQuantity(): number {

    const totalQuantity = this.cart.getCartItemsCount();
    return totalQuantity;

  }

  updateCartInfo(): void {
    
    var sum = 0;
    this.cart.CartItems.forEach(function(item){
      sum += item.subtotal;
    });
    this.subtotal = sum;
  }

  removeProductFromCart(cartItem: CartItem): number {
    const offeringId = cartItem.offeringId;
    var retVal = 0;
    if (offeringId !== undefined) {
      this.cart.CartItems.forEach((prod, i) => {

        if (offeringId === prod.offeringId) {
          retVal = i;
          this.cart.CartItems.splice(i, 1);
        }

      });
      //remove from questionslist
      this.questionsList.forEach((cartItem,i)=>{
        if(cartItem.offeringId == offeringId){
          this.questionsList.splice(i,1);
        }
      });
      this.updateCartInfo();
      //check if cart still has product as another offering
      var index = this.cart.hasProduct(cartItem.productId);
      if(index != -1){
        this.questionsList.splice(index,1);
        this.createQuestionForm();
        this.questionFormSubject.next(this.answerForm);
      }
    }
    return retVal;
  }
getResponses(){
  return this.answerForm.value;
}
getAddresses(){
  return this.addressesForm.value;
}
formValidator(formGroup : UntypedFormGroup){
  if(formGroup.controls.termsAndConditions !=null && (formGroup.controls.termsAndConditions.disabled || formGroup.controls.termsAndConditions.invalid)){
    return {'termsAndConditions':{
      termsAndConditions:'You Must Agree to the Terms and Conditions!'}
    }};
    if(formGroup.controls.productQuestionArray !=null && formGroup.controls.institutionType !=null){
      if(formGroup.controls.productQuestionArray.invalid || formGroup.controls.institutionType.invalid){
        return {'missingAnswers':{
          missingAnswers:'Some Sections are still missing answers!'}
        }
      }
    }
    return null;
  };
  

createQuestionForm(){
  var hasProficiencyTest = false;
  this.answerForm = new UntypedFormGroup({}, this.formValidator);
  var productQuestionArray = new UntypedFormArray([]);
  this.questionsList.forEach((item) => {
    if(!hasProficiencyTest && item.unit.toLowerCase().includes('proficiency')){
      hasProficiencyTest = true;
    }
    productQuestionArray.push(new UntypedFormGroup({questions: this.qaService.getNewFormGroup(item.id)}))

  }, this);
  this.answerForm.registerControl('productQuestionsArray',productQuestionArray);
  var control = new UntypedFormControl();
  this.answerForm.addControl('institutionType', control);
  if(hasProficiencyTest){
    var termsControl = new UntypedFormControl(false,[Validators.requiredTrue]);
    //termsControl.patchValue(false);
    termsControl.disable();
    this.answerForm.addControl('termsAndConditions',termsControl);
  }
} 


    resetCart(){
      this.cart = new Cart();
      this.addressesForm.reset();
      this.createQuestionForm();
      this.questionsList = [];
      
    }

    convertQuestionFormToResponses(){
      //for each question get the questionId, answerValue, productId, and offeringId
      var productArray= [];
      var qa = [];

      for(var i=0; i<this.cart.CartItems.length; i++){
        var item = this.cart.CartItems[i];
        for(var j=0; j < item.qa.length; j++){
          var answers = this.getAllAnswerValues(i,j, item.productId, item.offeringId, item.qa[j]['id'],item.qa[j]['answers'],item.productName, item.qa[j]['questionText']);
          //need to fix responses obect so it makes more sense.  must remove answers from array then add to qa
          for (var k=0;k<answers.length;k++){
            qa.push(answers[k]);
          }
          //qa.push(this.getAllAnswerValues(i,j, item.productId, item.offeringId, item.qa[j]['id'],item.qa[j]['answers'],item.productName, item.qa[j]['questionText']));
          
        }
        productArray.push(qa);
        qa = [];
      }
      
      return productArray;
    }

    getAllAnswerValues(productIndex,questionIndex, productId, offeringId, questionId, answerValues, productName, questionText){
      var returnArray = [];
      var responses = this.answerForm.get('productQuestionsArray.' + productIndex + '.questions.' + questionIndex).value;
      for(var i=0; i< answerValues.length; i++){
        //create a new QA Object for every response
        if(typeof(responses[i]) == 'boolean'){//checkbox value
          if(responses[i] == true){
            var qa = new QA(questionId, answerValues[i].answer_text, productId, offeringId,productName, questionText);
            returnArray.push(qa);
          }
        }
        else{//other control/radio or null value
          if(responses[i] != null){
            if(responses[i].other == true || responses[i].other == 'true'){//other text
              var qa = new QA(questionId, responses[i].otherText, productId, offeringId,productName, questionText);
              returnArray.push(qa);
            }
            else{//radio button
              if(responses[i].other != null && responses[i].other != false){//check for false in case someone clicks other, writes text, then unclicks
                var qa = new QA(questionId, responses[i].other, productId, offeringId,productName, questionText);
                returnArray.push(qa);
              }
            }
          }
        }

      }
      
      return returnArray;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //ADDRESS FORM
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    createAddressForm(){
    this.addressesForm = this.formBuilder.group(
      {
        shipping: this.formBuilder.group(
          {
            id :[''],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            institution: ['', Validators.required],
            email: ['', Validators.required],
            address: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
            country: ['', Validators.required],
            phone: ['', Validators.required],
            fax: [''],
            instructions: [''],
            po: [''],
            taxid: ['']
          }//, { 'validator ': this.onChanges }
        ),
        billing: this.formBuilder.group(
          {
            id:[''],
            firstname: [{value:'',disabled:true}, Validators.required],
            lastname: [{value:'',disabled:true}, Validators.required],
            institution: [{value:'',disabled:true}, Validators.required],
            email: [{value:'',disabled:true}, Validators.required],
            address: [{value:'',disabled:true}, Validators.required],
            address2: [{value:'',disabled:true}],
            city: [{value:'',disabled:true}, Validators.required],
            state: [{value:'',disabled:true}, Validators.required],
            zip: [{value:'',disabled:true}, Validators.required],
            country: [{value:'',disabled:true}, Validators.required],
            phone: [{value:'',disabled:true}, Validators.required],
            fax: [{value:'',disabled:true}]
          }
        )
      }
    );
    this.addressesForm.controls.shipping.statusChanges.subscribe(state => this.setBillingValid(state));

    this.addressesForm.valueChanges.subscribe(state => this.setAddressValid(state));
    this.addressFormSubject.next(this.addressesForm);
    
  }
  setBillingValid(state){
    if(state == 'VALID'){
      this.addressesForm.controls.billing.enable();
    }
  }
  setAddressValid(state){
      if (this.addressesForm.get('shipping').valid) {
        this.shippingValid = true;
      } else {
        this.shippingValid = false;
      }

      if (this.addressesForm.valid === true) {
        this.addressesFormValid  = true;
      } else {
        this.addressesFormValid = false;
      }

  }

}
