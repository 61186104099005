import { Injectable } from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MessageComponent } from '../../components/message/message.component';


/*
This service is for display error/success message boxes to the user.

To use:
1) Import MessageService from src/services/message
2) Add MessageService to constructor(private messageService : MessageService)
3) Use this.messageService.createMessage(status,message)
   Where status is either 'success' or 'error' and the message is a string (html is rendered as such)

*/

@Injectable()
export class MessageService {

  constructor(private modalService : NgbModal) { }

  createMessage(status,message){
    var modal = this.modalService.open(MessageComponent,{windowClass:'message-component', backdrop:'static'});
    modal.componentInstance.status = status;
    modal.componentInstance.message = message;
  }
}
