<h3 class="card-header full-card ps-1">Purchase Order</h3>
<div class="row ms-1">
    <p>The Center for Tobacco Reference Products requires that a signed Purchase Order be uploaded before the order can be processed.</p>
</div>


<h3 class="card-header full-card ps-1">Disclaimer</h3>
<div class="row ms-1">
    <p>The Center for Tobacco Reference Products requires that a signed disclaimer be uploaded with every order before that order can be processed.</p>
    <p>It is also possible that other documents may be required by law.  Please contact the Center for Tobacco Reference Products if you have questions.</p>
    <button class="btn btn-primary text-white" style="max-width: fit-content;" (click)="downloadDisclaimer()">Download unsigned disclaimer</button>
</div>

<h3 class="card-header full-card ps-1">Document Manager</h3>
<div class="row ms-1"> 
    <div class="col-5">
        <h5>Document Upload Instructions</h5>
        <ol>
          <li>Select the type of file document you want to upload.</li>
          <li>Select the file from your computer and click "Upload"</li>
          <li>Accepted file extensions: .pdf, .docx, .xlsx, .jpg, .png</li>
          <li style="color:red;">There are NO additional terms and conditions, and any attempt to add via purchase order or otherwise is VOID.</li>
        </ol>
        
        </div>
  
  <div class="col-7">
      <form [formGroup]="documentForm">
          <div class="form-group">
            <label>Select Category:</label>
              <select class="form-select" formControlName="category" required>
                <option value="signed_disclaimer">Signed Disclaimer</option>
                <option value="po">Purchase Order</option>
                <option value="taxdoc">Tax Authority</option>
                <option value="other">Other</option>
              </select>
          </div>
          <div class="form-group">
            <label>Document Description (optional, 150 characters max)</label>
            <textarea class="form-control" formControlName="description" rows="3" cols="30" maxlength="150"></textarea>
          </div>
          <div class="form-group">
            <label>Select File:</label>
            <input type="file" class="form-control" formControlName="file" (change)="onFileChange($event)">
          </div>
          <div class="form-group">
              <button class="btn btn-primary text-white mt-1" (click)="submitDocument()" [disabled]="documentForm.status != 'VALID' || fileValid == false || isUploading == true">Submit</button>
          </div>
      </form>

  </div>

    
  </div>
  <div class="row" style="overflow-x: auto;" *ngIf="ctrpfiles != null && ctrpfiles.length > 0">
      <table class="table">
        <thead><tr>
          <th style="width: 25%">File</th>
          <th style="width: 8.33%">Category</th>
          <th style="width:33.33%">Description</th>
          <th style="width: 8.33%">User</th>
          <th style="width: 8.33%">Created</th>
          <th style="width: 8.33%">Download</th>
          <th style="width: 8.33%">Delete</th>
        </tr></thead>
        <tbody>
          <tr *ngFor="let file of ctrpfiles">
            <td>{{file.fileName}}</td>
            <td>{{file.category}}</td>
            <td>{{file.description | isNull}}</td>
            <td>{{file.username}}</td>
            <td>{{file.created | date:'shortDate':'GMT'}}</td>
            <td>
                <a *ngIf="browserIsIE == false" class="downloadLink" target="_blank" [href]="createLink('download',file.id)" [download]="file.fileName">Download</a>
                <a *ngIf="browserIsIE == true" class="downloadLink" (click)="createLink('download',file.id)">Download</a>
            </td>
            <td class="col-1"><a class="deleteLink" (click)="deleteDocument(file)">Delete</a></td>
          </tr>
        </tbody>
      </table>
  
    </div>   