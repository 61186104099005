<div class="row content">
  <div class='col-md-3 order-md-2 mx-auto'>
    <button class="btn btn-primary btn-lg mt-0 ms-3 ps-5 pe-5 text-white" [disabled]="!cartService.addressesFormValid" (click)="onSubmit()">Continue >></button>
  </div>
  <div class='col-md-9 order-md-1 mb-3'>
      <h4>Fields marked <span style="color:#A92228;">red</span> are required.</h4>
    <label for="previousShipping" [hidden]="previousAddresses == null || previousAddresses.length == 0">Previous Shipping Addresses:</label>
    <select *ngIf="previousAddresses !=null && previousAddresses.length > 0" style="max-width: inherit;" (change)="previousShippingAddressChecked($event.target.value)" (focus)="previousShippingAddressChecked($event.target.value)" id="previousShipping">
        <option disabled selected value> -- select an option -- </option>
      <ng-container *ngFor="let a of previousAddresses; let i = index">
          <option *ngIf="a.addressType == 'shipping'" [value]="i">{{a.address}} {{a.city}} {{a.state}} {{a.zip}} {{a.country}}</option>
      </ng-container>
    
    </select>

    <form [formGroup]='addressesForm'> <!--(ngSubmit)="addToCart(addressesForm)"-->

      <h5 class="bg-secondary p-2 text-light">Shipping Address</h5>

      <div formGroupName="shipping">

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingFirstname" required formControlName = "firstname" placeholder="First name" type="string" maxlength="75">
            <div [hidden]="addressesForm.get('shipping.firstname').valid || addressesForm.get('shipping.firstname').pristine"
              class="alert alert-danger">First name required</div>
              <div [hidden]="addressesForm.get('shipping.firstname').pristine || addressesForm.get('shipping.firstname').value.length<71" class="alert alert-danger">Maximum Length: 70</div>
          </div>

          <div class="col-6 mb-1">

            <input type="text" class="form-control" id="shippingLastname" required formControlName="lastname" placeholder="Last name" type="string" maxlength="75">
            <div [hidden]="addressesForm.get('shipping.lastname').valid || addressesForm.get('shipping.lastname').pristine"
              class="alert alert-danger">Last name required</div>
              <div [hidden]="addressesForm.get('shipping.lastname').pristine || addressesForm.get('shipping.lastname').value.length<71" class="alert alert-danger">Maximum Length: 70</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingInstitution" required formControlName="institution" placeholder="Institution" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('shipping.institution').valid || addressesForm.get('shipping.institution').pristine"
              class="alert alert-danger">Institution required</div>
              <div [hidden]="addressesForm.get('shipping.institution').pristine || addressesForm.get('shipping.institution').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingEmail" required formControlName="email" placeholder="Email" type="string" pattern = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" maxlength="254">
            <div [hidden]="(addressesForm.get('shipping.email').untouched && !addressesForm.get('shipping.email').valid) || addressesForm.get('shipping.email').valid || addressesForm.get('shipping.email').pristine"
              class="alert alert-danger">Valid email required</div>
              <div [hidden]="addressesForm.get('shipping.email').pristine || addressesForm.get('shipping.email').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>
          

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingAddress" required formControlName="address" placeholder="Address" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('shipping.address').valid || addressesForm.get('shipping.address').pristine"
              class="alert alert-danger">Address required</div>
              <div [hidden]="addressesForm.get('shipping.address').pristine || addressesForm.get('shipping.address').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingAddress2" formControlName="address2" placeholder="Address 2" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('shipping.address2').pristine || addressesForm.get('shipping.address2').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingCity" required formControlName="city" placeholder="City" type="string" maxlength="50">
            <div [hidden]="addressesForm.get('shipping.city').valid || addressesForm.get('shipping.city').pristine" class="alert alert-danger">City required</div>
            <div [hidden]="addressesForm.get('shipping.city').pristine || addressesForm.get('shipping.city').value.length<46" class="alert alert-danger">Maximum Length: 45</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingState" required formControlName="state" placeholder="State/Province/Region" type="string" maxlength="65">
            <div [hidden]="addressesForm.get('shipping.state').valid || addressesForm.get('shipping.state').pristine"
              class="alert alert-danger">State/Province/Region required</div>
              <div [hidden]="addressesForm.get('shipping.state').pristine || addressesForm.get('shipping.state').value.length<61" class="alert alert-danger">Maximum Length: 60</div>
          </div>     
        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingZip" required formControlName="zip" placeholder="Zip" type="string" maxlength="25">
            <div [hidden]="addressesForm.get('shipping.zip').valid || addressesForm.get('shipping.zip').pristine" class="alert alert-danger">Zip required</div>
            <div [hidden]="addressesForm.get('shipping.zip').pristine || addressesForm.get('shipping.zip').value.length<21" class="alert alert-danger">Maximum Length: 20</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" required formControlName="country" placeholder="Country" list="ctrp_countries"/>
            <datalist id="ctrp_countries">
              <option *ngFor="let a of countryList.country_list" value="{{a}}">{{a}}</option>
            </datalist>
            <div [hidden]="addressesForm.get('shipping.country').valid || addressesForm.get('shipping.country').pristine"
              class="alert alert-danger">Country required</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingPhone" required formControlName="phone" placeholder="Phone" type="string" maxlength="55">
            <div [hidden]="addressesForm.get('shipping.phone').valid || addressesForm.get('shipping.phone').pristine"
              class="alert alert-danger">Phone required</div>
              <div [hidden]="addressesForm.get('shipping.phone').pristine || addressesForm.get('shipping.phone').value.length<51" class="alert alert-danger">Maximum Length: 50</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="shippingFax" formControlName="fax" placeholder="Fax" type="string" maxlength="55">
            <div [hidden]="addressesForm.get('shipping.fax').pristine || addressesForm.get('shipping.fax').value.length<51" class="alert alert-danger">Maximum Length: 50</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <textarea class="form-control" id="shippingInstructions" formControlName="instructions" placeholder="Shipping Instructions" type="string" maxlength="2505"></textarea>
            <div [hidden]="addressesForm.get('shipping.instructions').pristine || addressesForm.get('shipping.instructions').value.length<2501" class="alert alert-danger">Maximum Length: 2500</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control mb-1" id="shippingPo" formControlName="po" placeholder="PO number" type="string" maxlength="155">
            <div [hidden]="addressesForm.get('shipping.po').pristine || addressesForm.get('shipping.po').value.length<151" class="alert alert-danger">Maximum Length: 150</div>
            
            <input type="text" class="form-control" id="shippingTaxid" formControlName="taxid" placeholder="Tax ID" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('shipping.taxid').pristine || addressesForm.get('shipping.taxid').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

        </div>

      </div>

      <div class="bg-secondary p-2 text-light mt-4 mb-4" [hidden]="!cartService.shippingValid"> 
        <button (click)="useShippingAddressForBilling()" class="btn btn-primary btn-sm mt-0 ms-3 text-white"> {{ buttonText }}</button>
      </div>

      <div *ngIf="hasBillingAddresses">
      <label for="previousBilling">Previous Billing Addreses:</label>
      <select (focus)="previousBillingAddressChecked($event.target.value)" (change)="previousBillingAddressChecked($event.target.value)" id="previousBilling">
          <option disabled selected value> -- select an option -- </option>
          <ng-container *ngFor="let a of previousAddresses; let i = index">
              <option *ngIf="a.addressType == 'billing'" [value]="i">{{a.address}} {{a.city}} {{a.state}} {{a.zip}} {{a.country}}</option>
          </ng-container>
          
        </select>
      </div>
      <h5 class="bg-secondary p-2 text-light">Billing Address</h5>
      <div formGroupName="billing">

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingFirstname" required formControlName="firstname" placeholder="First name" type="string" maxlength="75">
            <div [hidden]="addressesForm.get('billing.firstname').valid || addressesForm.get('billing.firstname').pristine"
              class="alert alert-danger">First name required</div>
              <div [hidden]="addressesForm.get('billing.firstname').pristine || addressesForm.get('billing.firstname').value.length<71" class="alert alert-danger">Maximum Length: 70</div>
          </div>

          <div class="col-6 mb-1">

            <input type="text" class="form-control" id="billingLastname" required formControlName="lastname" placeholder="Last name" type="string" maxlength="75">
            <div [hidden]="addressesForm.get('billing.lastname').valid || addressesForm.get('billing.lastname').pristine"
              class="alert alert-danger">Last name required</div>
              <div [hidden]="addressesForm.get('billing.lastname').pristine || addressesForm.get('billing.lastname').value.length<71" class="alert alert-danger">Maximum Length: 70</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingInstitution" required formControlName="institution" placeholder="Institution" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('billing.institution').valid || addressesForm.get('billing.institution').pristine"
              class="alert alert-danger">Insitution required</div>
              <div [hidden]="addressesForm.get('billing.institution').pristine || addressesForm.get('billing.institution').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingEmail" required formControlName="email" placeholder="Email" type="string" pattern = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" maxlength="254">
            <div [hidden]="(addressesForm.get('billing.email').untouched && !addressesForm.get('billing.email').valid) || addressesForm.get('billing.email').valid || addressesForm.get('billing.email').pristine"
              class="alert alert-danger">Valid email required</div>
              <div [hidden]="addressesForm.get('billing.email').pristine || addressesForm.get('billing.email').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingAddress" required formControlName="address" placeholder="Address" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('billing.address').valid || addressesForm.get('billing.address').pristine"
              class="alert alert-danger">Address required</div>
              <div [hidden]="addressesForm.get('billing.address').pristine || addressesForm.get('billing.address').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingAddress2" formControlName="address2" placeholder="Address 2" type="string" maxlength="255">
            <div [hidden]="addressesForm.get('billing.address2').pristine || addressesForm.get('billing.address2').value.length<251" class="alert alert-danger">Maximum Length: 250</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingCity" required formControlName="city" placeholder="City" type="string" maxlength="50">
            <div [hidden]="addressesForm.get('billing.city').valid || addressesForm.get('billing.city').pristine" class="alert alert-danger">City required</div>
            <div [hidden]="addressesForm.get('billing.city').pristine || addressesForm.get('billing.city').value.length<46" class="alert alert-danger">Maximum Length: 45</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingState" required formControlName="state" placeholder="State/Province/Region" type="string" maxlength="65">
            <div [hidden]="addressesForm.get('billing.state').valid || addressesForm.get('billing.state').pristine" class="alert alert-danger">State required</div>
            <div [hidden]="addressesForm.get('billing.state').pristine || addressesForm.get('billing.state').value.length<61" class="alert alert-danger">Maximum Length: 60</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingZip" required formControlName="zip" placeholder="Zip" type="string" maxlength="25">
            <div [hidden]="addressesForm.get('billing.zip').valid || addressesForm.get('billing.zip').pristine" class="alert alert-danger">Zip required</div>
            <div [hidden]="addressesForm.get('billing.zip').pristine || addressesForm.get('billing.zip').value.length<21" class="alert alert-danger">Maximum Length: 20</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingCountry" required formControlName="country" placeholder="Country" type="string" maxlength="65">
            <div [hidden]="addressesForm.get('billing.country').valid || addressesForm.get('billing.country').pristine"
              class="alert alert-danger">Required</div>
              <div [hidden]="addressesForm.get('billing.country').pristine || addressesForm.get('billing.country').value.length<61" class="alert alert-danger">Maximum Length: 60</div>
          </div>

        </div>

        <div class="form-group row mb-0">

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingPhone" required formControlName="phone" placeholder="Phone" type="string" maxlength="55">
            <div [hidden]="addressesForm.get('billing.phone').valid || addressesForm.get('billing.phone').pristine" class="alert alert-danger">Phone required</div>
            <div [hidden]="addressesForm.get('billing.phone').pristine || addressesForm.get('billing.phone').value.length<51" class="alert alert-danger">Maximum Length: 50</div>
          </div>

          <div class="col-6 mb-1">
            <input type="text" class="form-control" id="billingFax" formControlName="fax" placeholder="Fax" type="string" maxlength="55">
            <div [hidden]="addressesForm.get('billing.fax').pristine || addressesForm.get('billing.fax').value.length<51" class="alert alert-danger">Maximum Length: 50</div>
          </div>
        </div>
      </div>
    </form>

    <!--<p>{{ addressesForm.value | json }}</p>-->
    <!--<p>{{ addressesForm.status | json }}</p>-->
    <!-- <p>shipping status : {{ addressesForm.get('shipping').status | json }}</p> -->
    <!-- <p>shipping firstname : {{ addressesForm.get('shipping.shippingFirstname').valid | json }} </p> -->

  </div>

  

</div>