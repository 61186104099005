  
    <nav class="navbar navbar-expand-md navbar-dark bg-dark text-uppercase">
        <div class="container-fluid d-print-none">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNav()">
            <span class="navbar-toggler-icon"></span>
        </button>
            <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2" [class.show]="isActive">
                <ul class="navbar-nav me-auto">
                        <li class="nav-item" id="navHome">
                                <a class="nav-link" routerLink="/home">Home</a>
                            </li>
                            <li class="nav-item" id="navProducts">
                                <a class="nav-link" routerLink="/products/gallery/Reference%20Cigarettes">Products</a>
                            </li>

                            <li class="nav-item" [hidden]="!loggedIn" id="navOrders">
                                <a class="nav-link" routerLink="/orders">My Orders</a>
                            </li>
                            <li class="nav-item" [hidden]="!loggedIn" id="navProfstudies">
                                <a class="nav-link" routerLink="/profstudies">My Proficiency Studies</a>
                            </li>
                </ul>
            </div>

            <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
                <ul class="navbar-nav ms-auto">
                        <li class="nav-item" id="navCart">
                                <a class="nav-link" id="cartLinkHeaderNav" routerLink="/cart/verifyorder">Cart
                                    <span *ngIf="cartService.cart.CartItems.length > 0" class="text-white">: {{cartService.cart.CartItems.length}} item(s)</span></a>
                        </li>
                </ul>
            </div>
        </div>
        </nav>