<h3 class="card-header full-card ps-1">Addresses</h3>
<div class="row">
  <div class="col"><h3>Billing</h3></div>
  <div class="col"><h3>Shipping</h3></div>
</div>
<div class="row">
  <div class="col">{{billingAddress.firstname}} {{billingAddress.lastname}}</div>
  <div class="col">{{shippingAddress.firstname}} {{shippingAddress.lastname}}</div>
</div>

<div class="row" [hidden]="showAll != true">
  <div class="col">{{billingAddress.institution}}</div>
  <div class="col">{{shippingAddress.institution}}</div>
</div>

<div class="row" [hidden]="showAll != true">
  <div class="col">{{billingAddress.email}}</div>
  <div class="col">{{shippingAddress.email}}</div>
</div>

<div class="row">
  <div class="col">{{billingAddress.address}}</div>
  <div class="col">{{shippingAddress.address}}</div>
</div>

<div class="row" [hidden]="showAll != true">
  <div class="col">{{billingAddress.address2}}</div>
  <div class="col">{{shippingAddress.address2}}</div>
</div>


<div class="row">
  <div class="col">{{billingAddress.city}} {{billingAddress.state}} {{billingAddress.zip}}</div>
  <div class="col">{{shippingAddress.city}} {{shippingAddress.state}} {{shippingAddress.zip}} </div>
</div>
<div class="row">
    <div class="col">{{billingAddress.country}}</div>
    <div class="col">{{shippingAddress.country}}</div>
</div> 

<div class="row" [hidden]="showAll != true">
  <div class="col">{{billingAddress.phone}}</div>
  <div class="col">{{shippingAddress.phone}}</div>
</div>

<div class="row" [hidden]="showAll != true">
  <div class="col"></div>
  <div class="col">{{shippingAddress.po}} <br/>
    {{shippingAddress.taxid}}
  <br/>
  {{shippingAddress.instructions}}
  </div>

</div>