<div class="card">
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4 text-center">
    <button class="btn btn-primary text-white" (click)="submitData($event)">Confirm and Submit</button>
  </div>
  <div class="col-4"></div>
  </div>
  <ng-container *ngIf="confirmData !=null">
    <div class="row">
      <div class="col text-end font-weight-bold">Institution Collecting Data:</div>
      <div class="col text-start">{{confirmData.institution}}</div>
      <div class="col text-end font-weight-bold">CTRP Proficiency Study ID:</div>
      <div class="col text-start">{{confirmData.studyId}}</div>
    </div>
    <div class="row">
      <div class="col text-end font-weight-bold">Tobacco Product Name:</div>
      <div class="col text-start">{{confirmData.productName}}</div>
      <div class="col text-end font-weight-bold">CTRP Assigned Dataset ID:</div>
      <div class="col text-start">{{confirmData.dataId}}</div>
    </div>
    <div class="row">
      <div class="col text-end font-weight-bold">Proficiency Study Order Date:</div>
      <div class="col text-start">{{confirmData.orderDate | date:'shortDate':'GMT'}}</div>
      <div class="col text-end font-weight-bold">ISO Accreditation:</div>
      <div class="col text-start">{{confirmData.iso}}</div>
    </div>
  </ng-container>
</div>