import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

//import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';

// App initializer
import { AppLoadModule } from './app-load/app-load.module';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Services
import { ProductsService } from './services/products/products.service';
import { CartService } from './services/cart/cart.service';
import { ProfstudyService } from './services/profstudy/profstudy.service';
import { FileService } from './services/file/file.service';
import { QaService } from './services/qa/qa.service';
import { RouteguardService } from './services/routeguard/routeguard.service';
import { OrdersService } from './services/orders/orders.service';
import { AuthorizedService } from './services/authorized/authorized.service';
import { QuestionService } from './services/question/question.service';

// Application components
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProductsmainComponent } from './components/products/productsmain/productsmain.component';
import { GalleryComponent } from './components/products/gallery/gallery.component';
import { DetailComponent } from './components/products/detail/detail.component';
import { MenuComponent } from './components/products/menu/menu.component';
import { ShippingComponent } from './components/shipping/shipping.component';
import { CartmainComponent } from './components/cart/cartmain/cartmain.component';
import { AddressesComponent } from './components/cart/addresses/addresses.component';
import { QuestionsComponent } from './components/cart/questions/questions.component';
import { FinalizeComponent } from './components/cart/finalize/finalize.component';
import { VerifyorderComponent } from './components/cart/verifyorder/verifyorder.component';
import { NewsComponent } from './components/news/news.component';
import { ProfstudyComponent } from './components/profstudy/profstudy.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProfstudydetailComponent } from './components/profstudy/profstudydetail/profstudydetail.component';
import { FilePickerComponent } from './components/filepicker/filepicker.component';
import { ProfstudyreviewComponent } from './components/profstudy/profstudyreview/profstudyreview.component';
import { ConfirmandsubmitComponent } from './components/profstudy/profstudyreview/confirmandsubmit/confirmandsubmit.component';
import { MachinedataComponent } from './components/profstudy/profstudyreview/machinedata/machinedata.component';
import { SectiondataComponent } from './components/profstudy/profstudyreview/sectiondata/sectiondata.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailsComponent } from './components/orders/orderdetails/orderdetails.component';
import { OrderAddressComponent } from './components/orders/orderaddress/orderaddress.component';
import { OrderDocumentManager } from './components/orders/documentmanager/documentmanager.component';
import { MessageComponent } from './components/message/message.component';
import { MessageService } from './services/message/message.service';
import { OrderbalancesheetComponent } from './components/orders/orderbalancesheet/orderbalancesheet.component';
import { ProfstudyscheduleComponent } from './components/profstudyschedule/profstudyschedule.component';
import { NewsService } from './services/news/news.service';
import { ResponsesComponent } from './components/orders/responses/responses.component';
import { AuthenticatepageComponent } from './components/authenticatepage/authenticatepage.component';
import { CommentComponent } from './components/profstudy/profstudydetail/comment/comment.component';
import { AccreditationComponent } from './components/accreditation/accreditation.component';
import { PhysicaldataComponent } from './components/profstudy/profstudyreview/physicaldata/physicaldata.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ToUpperCasePipe } from './custom.pipe';
import { NullPipe } from './custom.pipe';
import { ExtendedNewsComponent } from './components/extendednews/extendednews.component';
import { TsrcComponent } from './components/tsrc/tsrc.component';
import { IntegerDirective } from './integer.directive';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        ProductsmainComponent,
        GalleryComponent,
        DetailComponent,
        MenuComponent,
        ShippingComponent,
        CartmainComponent,
        AddressesComponent,
        QuestionsComponent,
        FinalizeComponent,
        VerifyorderComponent,
        NewsComponent,
        ProfstudyComponent,
        RegistrationComponent,
        NavbarComponent,
        ProfstudydetailComponent,
        FilePickerComponent,
        ProfstudyreviewComponent,
        ConfirmandsubmitComponent,
        MachinedataComponent,
        OrdersComponent,
        OrderDetailsComponent,
        OrderAddressComponent,
        OrderDocumentManager,
        SectiondataComponent,
        MessageComponent,
        OrderbalancesheetComponent,
        ProfstudyscheduleComponent,
        ResponsesComponent,
        AuthenticatepageComponent,
        CommentComponent,
        AccreditationComponent,
        PhysicaldataComponent,
        DocumentsComponent,
        ToUpperCasePipe,
        NullPipe,
        NotfoundComponent,
        ExtendedNewsComponent,
        TsrcComponent,
        IntegerDirective
    ],
    imports: [
        AppLoadModule,
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        //HttpClientModule,
        NgbModule,
        CommonModule,
        BrowserAnimationsModule
    ],
    providers: [
        ProductsService,
        CartService,
        ProfstudyService,
        FileService,
        QaService,
        RouteguardService,
        OrdersService,
        MessageService,
        NewsService,
        AuthorizedService,
        QuestionService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
 