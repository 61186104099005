
<div class="row content" *ngIf="cartItems.length > 0; else noItems">
    <div class='col-md-3 order-md-2 mb-1' *ngIf="answerForm != null">
        <button class="btn btn-primary btn-lg mt-0 ms-3 ps-5 pe-5 mb-2 text-white" [disabled]="!answerForm.valid" [hidden]="cartService.cart.CartItems.length == 0" (click)="onSubmit()">Continue >></button>
    </div>
<div class="col-md-9 order-md-1">
    <form [formGroup]="answerForm" (ngSubmit)="onSubmit(answerForm.value, answerForm.valid)">
        <h4>You must answer all questions before proceeding.</h4>
            <div formArrayName="productQuestionsArray" *ngFor="let item of cartService.questionsList; let i=index">
            <ng-container formGroupName="{{i}}">
            <h3 class="card-header ps-1"> {{ item.productName }} </h3>
              <div class="ps-3" formArrayName="questions" *ngFor="let q of item.qa; let j = index">
                <h4> {{ q.questionText }} </h4>
                <ng-container *ngIf="q.questionType == 'checkbox' then checkbox; else radio;"></ng-container>
                  <ng-template #checkbox>
                    <div class="ps-3 question-group" [formArrayName]="j" *ngFor="let a of q.answers; let k = index">
                        <ng-container *ngIf="a.answer_text == 'Other' then cbOther else cbNormal"></ng-container>      
                      <ng-template #cbNormal>
                          <label>
                              <input type="checkbox" [formControlName]="k" [(value)]='a.answer_text'>
                              {{ a.answer_text }}
                          </label>
                        </ng-template>
                            
                        <ng-template #cbOther>
                          <ng-container [formGroupName]="k">
                              <label>
                                <input type="checkbox" formControlName="other" /> Other
                              </label><br/>
                              <ng-container *ngIf="checkForOther(i,j,k)">
                                  <input type="textarea" class="col-6" rows="3" cols="35" maxlength="512" placeholder="Please Specify" formControlName="otherText" />
                                  <div class="text-danger min-length">Must not be empty!</div>
                              </ng-container>
                            </ng-container>
                        </ng-template>
                      </div>
                  </ng-template>
                  <ng-template #radio>
                    <ng-container [formArrayName]="j">
                      <div formGroupName="0" class="ps-3 question-group">
                      <ng-container *ngFor="let a of q.answers;">
                          <label>
                            <input *ngIf="a.answer_text != 'Other'" type="radio" formControlName='other' [value]="a.answer_text" />
                            <input *ngIf="a.answer_text =='Other'" type="radio" formControlName='other' value=true /> {{a.answer_text}}
                          </label><br/>
                          <ng-container *ngIf="a.answer_text == 'Other' && checkForOther(i,j)">
                              <input type="textarea" class="col-6" formControlName="otherText" placeholder="Please Specify" rows="3" cols="35" maxlength="512"/>
                              <div class="text-danger min-length">Must not be empty!</div>
                          </ng-container>
                      </ng-container>
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          
      <h4>Institution Type</h4>
      <div class="ps-3">
        <label><input type="radio" formControlName='institutionType' required value='Governmental'> Governmental</label><br/>
        <label><input type="radio" formControlName='institutionType' required value='Non-Governmental'> Non-Governmental</label><br/>
      </div> 
      <p class="text-danger">There are NO additional terms and conditions, and any attempt to add via purchase order, email, or otherwise is VOID.<br/>
      Orders over $1,000 require a purchase order to be submitted before they can be shipped!
      </p>
      <div *ngIf="answerForm != null && answerForm.get('termsAndConditions') !=null">
        <h4>Proficiency Study terms and Conditions</h4>
        <p>Please read the terms and conditions for purchasing a proficiency study.</p>
        <a *ngIf="browserIsIE == false" class="downloadLink" (click)="agreeToTerms()" target="_blank" href="/refcig-services/downloadTermsAndConditions" download="TermsAndConditions.pdf">Download Terms and Conditions</a>
        <a *ngIf="browserIsIE == true" class="downloadLink" (click)="downloadTermsIE()">Download Terms and Conditions</a><br/>
        <label><input type="checkbox" formControlName='termsAndConditions'> I Agree</label><br/>
      </div>
    </form>


  <div *ngIf="answerForm !=null">
    <p [hidden]="answerForm ==null || answerForm.valid" class="bg-danger h4 text-white">Some questions are still missing answers.</p>
    <button [disabled]="!answerForm.valid" [hidden]="cartService.cart.CartItems.length == 0" class="btn btn-primary btn-lg mt-0 ms-3 ps-5 pe-5 text-white" (click)="onSubmit()">Continue >></button>
  </div>

  </div>

</div>


<ng-template #noItems>
    <p class="bg-danger p-3 m-2 h5 text-white" style="max-width:550px">You have not added any items to your shopping cart yet!</p>
    <a routerLink="/products/gallery/Reference%20Cigarettes" class="btn btn-primary mt-0 ms-3 text-white">View Products</a>
  </ng-template> 
 

<!--
<div *ngIf="cartItems.length > 0">
  <p>Form is valid: {{answerForm.valid}}</p>
  <p>Form value: {{ answerForm.value | json }}</p>
</div>-->
