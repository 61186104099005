import { Purchase } from "./purchase";
import { Round } from "./round";
import { Purchasedetail } from "./purchasedetail";

export class Profstudy{
    id : number;
    notes : string;
    notesEdited : string;
    institution : string;
    username : string;
    authority : string;
    isoAccreditation : string;
    status : string;
    ctrpFile : number;
    purchasedetail : Purchasedetail;
    purchase : Purchase;
    round : Round;
    submittedDataFile : number;
    roundName: string;
  constructor(){
    this.id = -1;
    this.notes = "";
    this.notesEdited = "";
    this.institution = "";
    this.username = "";
    this.authority = "";
    this.isoAccreditation = "";
    this.status = "";
    this.ctrpFile = -1;
    //this.purchasedetail = new Purchasedetail();
    //this.purchase = new Purchase();
    this.round = new Round();
    this.submittedDataFile = -1;

  }
  
    /*
    constructor(json:any){
        this.id = json.id;
        this.notes = json.notes;
        this.notesEdited = json.notesEdited;
        this.institution = json.institution;
        this.username = json.username;
        this.authority = json.authority;
        this.isoAccreditation = json.isoAccreditation;
        this.status = json.status;
        this.ctrpFile = json.ctrpFile;
        this.purchasedetail = new Purchasedetail(json.purchasedetail);
        this.purchase = new Purchase(json.purchasedetail.purchase);
        this.round = new Round(json.round);
        this.submittedDataFile = json.submittedDataFile;

    }
*/
}

export class SelectedProfstudies{
    roundName: string;
    purchasedStudies: Array<Profstudy>;
    constructor(){
        this.roundName = "";
        this.purchasedStudies = new Array<Profstudy>();

    }
}

export class Profstudy2 {
}
