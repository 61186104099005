export class User {
	public authorities: string[];
	public authorityInstitution: {institution:string,authority:string}[];
	public email: string;
	public username: string;
	public telephone: string;
constructor(){
	this.username = null;
	this.telephone = null;
	this.authorityInstitution = null;
	this.email = null;
	this.authorities = null;
}
	setValues(response){
		this.username = response.username;
		this.telephone = response.telephone;
		this.email = response.email;
		this.authorities = response.authorities;
		this.authorityInstitution = response.authorityInstitution;
	}

}