import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cartmain',
  templateUrl: './cartmain.component.html',
  styleUrls: ['./cartmain.component.css']
})
export class CartmainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
