  <div class="card-header">
      <!-- Not a Registered User? 
        -->
        <!-- Please Register to Order Products -->
        To Order Products You Must be a Registered User
  </div>


  <div class="card-body">
    

    <p *ngIf="!submitted" class="card-text">
      To become a registered user, please complete the following information and await the confirmation email. 
      All fields are required.
    </p>

    <p *ngIf="submitted" class = "card-text">
      <b>{{submittedFormResponse}}</b>
    </p>
    
    <form *ngIf = "!submitted" (ngSubmit)="onSubmit()" #registrationForm="ngForm" autocomplete="off">
      
      <div class="form-group row" style="margin-bottom:1rem;">
        <div class="col">
          <input type="text" placeholder="Full Name" class="form-control" id="name" required [(ngModel)]="registrationModel.name" name="name" #name='ngModel' maxlength="100">
          <div [hidden]="name.valid || name.pristine" class="alert alert-danger"> required </div>
        </div>
        <div class="col d-none">
          <input type="text" placeholder="Address" class="form-control" id="address" [(ngModel)]="registrationModel.address" name="address" #address="ngModel" maxlength="100">
        </div>
        <div class="col">
            <input type="text" placeholder="Phone Number" class="form-control" id="phone" required [(ngModel)]="registrationModel.phone" name="phone" #phone='ngModel' maxlength="100" >
            <div [hidden]="phone.valid || phone.pristine" class="alert alert-danger"> required </div>
        </div>
      </div>

      <div class="form-group" style="margin-bottom:1rem;">
        <input type="text" placeholder="Institution" class="form-control" id="institution" required [(ngModel)]="registrationModel.institution" name="institution" #institution='ngModel' maxlength="100">
        <div [hidden]="institution.valid || institution.pristine" class="alert alert-danger"> required </div>
      </div>

      <div class="form-group">
          <input type="email"#spy  pattern = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$" placeholder="Email" class="form-control" id="email" required [(ngModel)]="registrationModel.email" name="email" #email='ngModel' maxlength="100">
          <div [hidden]="(email.untouched && !email.valid) || email.valid || email.pristine" class="alert alert-danger"> Please enter a valid Email </div>
      </div>

      <div class= "form-group row" #question style="margin-bottom:1rem;">
          <div class="col-sm-8">
            <label><b>Enter the sum of {{valueA}} and {{valueB}}:</b></label>
            <input type = "text" placeHolder=" " class="form-control" required maxlength="8" [(ngModel)]="registrationModel.answer" name="answer">
            <div *ngIf = "showAnswerWarning" class="alert alert-danger"> Your answer is incorrect </div>
          </div>
        <br>
      </div>

      <button type="submit" class="btn formSubmitBtn"  style="border:0px;" [disabled] = "!registrationForm.form.valid">Become a Registered User</button>

    </form >
  </div>