import { Component, OnInit, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../services/orders/orders.service';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { ResponsesComponent } from '../responses/responses.component';

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.css']
})
export class OrderDetailsComponent implements OnInit {

  details : any;
  billing : any;
  ctrpfiles : any;
  ordersSubscription : Subscription;
  detailSubscription : Subscription;
  billingSubscription : Subscription;
  ctrpfileSubscription : Subscription;
  otherCharges : Array<any>;
  payments : Array<any>;
  shippingCharge : number;
  balanceDue : number;
  purchaseId : number;
  productTotal : number;
  paramSub : any;
  hasPO : boolean;
  //toggleVar : boolean = false;
  @ViewChild(ResponsesComponent)
  private responseComponent: ResponsesComponent;
  constructor(private route : ActivatedRoute, private ordersService : OrdersService, private authorizedService : AuthorizedService) {
    this.detailSubscription = this.authorizedService.detailSubject.subscribe(res => this.setDetails(res));
    this.billingSubscription = this.authorizedService.billingSubject.subscribe(res => this.setBilling(res));
    this.ctrpfileSubscription = this.authorizedService.ctrpfileSubject.subscribe(res => this.setCtrpfiles(res));
  }

  ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
        this.ordersService.requestPurchaseDetails(params['purchaseId']);
        this.purchaseId = params['purchaseId']; 
   });
  }
  setDetails(res){
    this.details = res;
    //debugger;
    this.productTotal = 0;
    if(res != null){
      res.purchasedetails.forEach(element => {
        this.productTotal += element.subtotal;
      });
    }
    console.log("setdetails");
  }
  setCtrpfiles(res){
    this.ctrpfiles = res;
    this.hasPO = false;
    console.log("setctrpfiles");
      if(res.length > 0){
        for(let i=0;i<res.length;i++){
          if(res[i].category == "po"){
            this.hasPO = true;
            i+=99;
          }
        }
      }
  }
  setBilling(res){
    //need to parse out shipping charge and other charges/payments
    this.billing = res;
    this.payments = [];
    this.otherCharges = [];
    this.balanceDue = 0;
    for(var i=0; i<res.length;i++){
      //debugger;
      if(res[i].billingtype.id==3){
        this.shippingCharge = res[i].amount;
        this.balanceDue += res[i].amount;
      }
      else if(res[i].billingtype.debitcredit == 1 && res[i].billingtype.id !=0){
        this.otherCharges.push(res[i]);
        this.balanceDue += res[i].amount
      }
      else if(res[i].billingtype.debitcredit == -1){
        this.payments.push(res[i]);
        this.balanceDue -= res[i].amount
      }
      else{
        this.balanceDue += res[i].amount;
      }
    }
  }
  onPrintOrderSummary(value){
    //need to make sure that the questions are opened
    this.responseComponent.toggleView = true;
    this.responseComponent.print = true;

  }
}
