import { Component, OnInit} from '@angular/core';
import { Observable ,  Subject ,  Subscription } from 'rxjs';
import { Login }    from '../../models/login';
import { User } from '../../models/user';
import {AuthorizedService } from '../../services/authorized/authorized.service'
import { Router } from '@angular/router';
import { MessageService } from '../../services/message/message.service';


@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
	model = new Login();
	loginSubscription: Subscription;
  userInfo = new User();
  userObserver = new Observable<User>();
	loggedIn = false;
	firstCheck = true;
  constructor(private authorizedService:AuthorizedService, private router:Router, private messageService : MessageService) {
	}
  
  ngOnInit() {
		//check to see if user is just reloading page and is still logged in
		this.loginSubscription = this.authorizedService.getLoginStatus().subscribe(user => this.loginStatusChanged(user));
  }
  onSubmit() { 
	  this.submitted = true; 
	  var formData = new FormData();
	  formData.append('username',this.model.username.toLowerCase());
		formData.append('password',this.model.password);
		this.authorizedService.requestLogin(formData);
	}

loginStatusChanged(response){ 
		//if user is not logged in, response will be an empty object so check for this
		//debugger;
		if(response.username){
			this.model = new Login();
			this.userInfo.setValues(response);
			this.loggedIn = true;

		}
		else{
			this.loggedIn = false;
			if(this.firstCheck == false){
				if(typeof(response.error) === "object"){
					this.messageService.createMessage('error', response.error.message);
				}
				else{//another type of error occured, possible internet connection failure
					this.messageService.createMessage('error',response.error);
				}

			}
			else{
				this.firstCheck = false;
			}
		}

}

  onLogoutClick(){
		this.authorizedService.requestLogOut();
		
		this.router.navigate(['home']);
		this.firstCheck = true;
  }
 
}