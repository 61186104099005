import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.css']
})
export class ResponsesComponent  {
  @Input() responses : any;
  public toggleView : boolean = false;
  public toggleIcon : string[];
  public print : boolean = false;
  constructor() { 
    this.toggleIcon = ['+','-'];
  }


  toggle(){
    this.toggleView = !this.toggleView;
  }

  ngAfterViewChecked(){
    if(this.print){
      window.print();
      this.print = false;
    }
  }
}
