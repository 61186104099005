import { Component } from '@angular/core';
import { AuthorizedService } from './services/authorized/authorized.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  isMasked = false;
  maskMessage = "";
  constructor(private authorizedService : AuthorizedService){
    this.authorizedService.maskSubject.subscribe((res)=>this.toggleMask(res));
    if(this.isIE()){
      alert("IE 11 IS NO LONGER A SUPPORTED BROWSER!!\n  Please user the most recent version of MS Edge, Chrome, or Firefox.");
    }
  }
  toggleMask(res){
    if(res === false){
      this.isMasked = res;
    }
    else{//res is a string with a message to display
      this.isMasked = true;
      this.maskMessage = res;
    }
    
  }

  isIE() {
    // IE 10 and IE 11
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }
}
