import { Component } from '@angular/core';
import { ProductsService } from '../../../services/products/products.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent {

  products = null ;

  constructor(public productService: ProductsService) {
    this.productService.productsSubject.subscribe(products => this.setProducts(products));
   }

   setProducts(products){
     this.products = products;
   }

   createLink(category){
     return '/gallery/'+category.replace(' ', '_');
   }

}
