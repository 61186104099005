  
<ng-container *ngIf="machineData.architecture == 'linear' || machineData.architecture == 'rotary' then machineDataTemplate; else otherData" ></ng-container>


<ng-template #machineDataTemplate>
  <div class="card">
    <div class="card-header bg-excel-header text-light">
      {{machineData.architecture | toUppercase}} Machine Data
    </div>
    <div class="row">
      <div class="col text-end">
        <strong>Machine Make:</strong>
      </div>
      <div class="col">
        {{machineData.make}}
    </div>
    <div class="col">
      <strong>Notes for Machine Data Collection:</strong>
    </div>
    </div>
    <div class="row">
      <div class="col text-end"> 
        <strong>Machine Model:</strong>
      </div>
      <div class="col">
        {{machineData.model}}
      </div>
      <div class="col card-block">
        {{machineData.notes}}
      </div>
    </div>
  </div>
</ng-template>



<ng-template #otherData>
  <div class="card">

    {{machineData.architecture}}
    <div class="card-header bg-excel-header text-light">Notes for Data Collection</div>
    <div class="card-body">{{machineData.notes}}</div>
  </div>
</ng-template>

