<h3>ISO Accreditation</h3>
<p>The proficiency testing scheme of CTRP is accredited by A2LA.</p>
 
<!--
<span>
  <img src="/assets/img/a2la.jpg" class="ms-1" style="display:inline; float:right;" width="207px"/><br/>
  <span style="font-size:9px; display:inline; float:right;">[ISO/IEC 17043; A2LA Certificate Number 4034.01]</span>
</span>-->
  
<div class="row">
  <div class="col-md-9">
    <p><a href="/assets/pdf/webdocs/2017A2LACertification.pdf">CTRP Accreditation Certificate</a><br/>
      <a href="/assets/pdf/webdocs/A2LACertificationScope.pdf">Scope of Accreditation</a>
      </p>
      
      <p>
        This accreditation confirms that we comply with the requirements of the International Standard ISO/IEC 17043:2010
      </p>
      
      <p>
        CTRP (and other proficiency testing schemes) do not award accreditation. That is the responsibility of national accreditation bodies. 
        A list of national and international accreditation bodies can be found at <a href="http://www.fasor.com/iso25">www.fasor.com/iso25. </a>
        Results of proficiency testing are used by laboratory accreditation bodies as part of the process to assess the ability of laboratories to perform analytical tests for which accreditation is required.
      </p>
  </div>
  <div class="col-md-3">
    <img src="/assets/img/a2la.jpg" class="ms-1" width="207px"/><br/>
    <span style="font-size:9px">[ISO/IEC 17043; A2LA Certificate Number 4034.01]</span>
  </div>
</div>