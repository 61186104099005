import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { CartService } from '../../../services/cart/cart.service';
import { QaService } from '../../../services/qa/qa.service';
import { AuthorizedService } from '../../../services/authorized/authorized.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {

  cartItems : Array<any> = Array<any>();
  showCart: Boolean = false;
  browserIsIE: Boolean;
  public answerForm: UntypedFormGroup;

  constructor(
    private router: Router,
    public cartService: CartService,
    public qaService: QaService,
    
    public fb: UntypedFormBuilder,
    private authorizedService : AuthorizedService
  ) {
    if(document['documentMode'] == null){
      this.browserIsIE = false;
    }
    else{
      this.browserIsIE = true;
    }
    router.events.subscribe((val) => { });
    this.cartService.questionFormSubject.subscribe(form => this.setForm(form));
    this.cartItems = this.cartService.getCart().CartItems;
  }
  downloadTermsIE(){
    this.agreeToTerms();
    this.authorizedService.getFile('/refcig-services/downloadTermsAndConditions');
  }

  checkForOther(i,j,k=-1){
    if(k == -1){
      return this.answerForm.get('productQuestionsArray.' + i + '.questions.' + j + '.0.other').value == 'true';
    }
    return this.answerForm.get('productQuestionsArray.' + i + '.questions.' + j + '.' + k + '.other').value == true;
  }

  onSubmit() {
    this.router.navigate(['cart/finalize/']);
  }
  setForm(answerForm){
    this.answerForm = answerForm;
    this.cartItems = this.cartService.getCart().CartItems;
  }
 
  agreeToTerms(){
    this.answerForm.get('termsAndConditions').enable();
  }

}
