
<div class="card" style="overflow-x: auto; border-bottom:0;">
  <div *ngIf="sectionHeader!=null; else defaultHeader" [style]="headerStyle" class="card-header bg-excel-header text-light" [innerHTML]="sectionHeader">
    
  </div>
  <ng-template #defaultHeader>Physical Parameters</ng-template>
  <div class="card-block">


<table  *ngFor="let t of physicalData" class="table mb-5">
<thead>
  <tr>
    <th *ngFor="let header of t['html']" class="text-center bg-excel-column custom-header" [innerHTML]="header.html"></th>
  </tr>
</thead>
<tbody>
  <tr *ngFor = "let row of t['tableData']">
    <td *ngFor = "let data of row" class="text-center">
      {{data}} <span *ngIf="data == null">&lt;empty&gt;</span>
    </td>
  </tr>
<!--now the machine data-->

  <tr>
    <ng-container *ngFor="let row of t['machines']">
        <td class="text-end bg-excel-column custom-header pe-1">Make:</td>
        <td class="text-center align-middle">{{row.make}}</td>
      </ng-container>
  </tr>
  <tr>
      <ng-container *ngFor="let row of t['machines']">
          <td class="text-end bg-excel-column custom-header pe-1">Model: </td>
          <td class="text-center align-middle">{{row.model}}</td>
          </ng-container>
    </tr>
    <tr>
        <ng-container *ngFor="let row of t['machines']">
            <td class="text-end bg-excel-column custom-header pe-1">Date:</td>
            <td class="text-center align-middle">{{row.date | date:'shortDate':'GMT'}}</td>
            </ng-container>
      </tr>
</tbody>
</table>

</div>
</div>