import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { Answer } from '../../models/answer';

@Injectable()
export class QaService {

  qa;
  data;
  formObject = { 'questions': [] };
  questionsForm: UntypedFormGroup;
  genericFormArray: UntypedFormArray;
  constructor() {
  }

  // Called from app-load.service
  setQA(data: any) {

    this.qa = data;
    this.data = data;
  }

  getQa(): any {
    return this.data;
  }
  createGenericFormArray(){
    var fa = new UntypedFormArray([]);
    this.qa.forEach(q => {
      if(q.questionType == 'checkbox'){
        fa.push(this.createCheckboxFormArray(q.answers));
      }
      else{//radio box
        fa.push(this.createRadioFormGroup(q.answers));
      }
    });
    return fa;
  }

  createCheckboxFormArray(answers : Answer[]){
    var controls = new Array();
    answers.forEach(a => {
      if(a.answer_text == "Other"){
        controls.push(new OtherFormGroup(this.otherValidator))
      }
      else{
        controls.push(new UntypedFormControl());
      }
    });
    return new UntypedFormArray(controls,this.AtLeastOneFieldValidator);
  }

  createRadioFormGroup(answers:Answer[]){
    var controls = new Object();
    var fg = new UntypedFormArray([]);

    fg.push(new OtherFormGroup(this.otherValidator));
    return fg;

  }

  getNewFormGroup(productId){
    //return a FormArray representing all the questions belonging to the requested product
    return this.createGenericFormArray();
  }

  AtLeastOneFieldValidator(array: UntypedFormArray): {[key: string]: any} {
    let isAtLeastOne = false;
    if (array && array.controls) {
      for (var i =0; i < array.controls.length; i++) {
        var val = array.controls[i].value;
        if(array.controls[i].validator == null){
          if(val == true){
            isAtLeastOne = true;
          }
        }
        else{//control has own validator
          if(isAtLeastOne == false ){//no options selected yet
            if(array.controls[i]['controls'].other.value == true){//check if other is selected, if it is use otherValidator to determine status
              isAtLeastOne = array.controls[i].valid;
            }
          }
        }
      }
    } 
    if(isAtLeastOne){
      return null;
    }
    return {'failed': true };
  }
  
  
  otherValidator(formGroup){
    var otherValue = formGroup.controls.other.value;
    if(otherValue == 'true'){
      otherValue = true;
    }
    if(otherValue != true){//when other hasn't been selected or is set to somthing else
      return null;
    }
    else if(otherValue == true && formGroup.controls.otherText.value != null && formGroup.controls.otherText.value != ''){//text field has more than empty string
      return null;
    }
    return {'minLength':{
      minLength:'Length must be greater than 0.'}
    }};
  
  }
  
  
  export class OtherFormGroup extends UntypedFormGroup{
  /*
  The value of a FormGroup is just an object with the value of each FormControl in the group.  OtherFormGroup returns the value of the 
  textbox control or the other controls depending on if 'other' is selected
  */
    constructor(validatorOrOpts = null, asyncValidator = null){
      
      super({other:new UntypedFormControl(), otherText: new UntypedFormControl()}, validatorOrOpts, asyncValidator);
    }
    getRealValue(){
      if (this.value['other'] == true || this.value['other'] == 'true'){
        return this.value['otherText'];
      }
      return this.value['other'];
    }
  }