import { Injectable } from '@angular/core';
import { AuthorizedService } from '../authorized/authorized.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private 
  constructor(authorizedService : AuthorizedService) {}



}
