import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfstudyService } from '../../services/profstudy/profstudy.service';

@Component({
  selector: 'app-profstudyschedule',
  templateUrl: './profstudyschedule.component.html',
  styleUrls: ['./profstudyschedule.component.css']
})
export class ProfstudyscheduleComponent implements OnInit {
  roundSubscription : Subscription;
  profstudyService : ProfstudyService;
  rounds : any;
  constructor(profstudyService : ProfstudyService) { 
    this.roundSubscription = profstudyService.roundSubject.subscribe(res => this.recievedRounds(res));
    this.profstudyService = profstudyService;
    this.rounds = [];
  }

  ngOnInit() {
  }

  recievedRounds(rounds){
    if(this.profstudyService !=null){
      for(var i=0;i<3;i++){
        var status = rounds[i].statusText;
        this.rounds[i] = {'name':rounds[i].profStudyReferenceId,'status':status}
      }
    }
  } 
}
