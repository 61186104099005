export class Profstudymenu {

    public 'hidden': Boolean;
    public 'profstudies': Array<any>;

    constructor() {
        this.hidden = true;
        this.profstudies = [];
    }

}
export class ProfstudyObject{
        
constructor(){
    var currentYear = new Date().getFullYear();
        for(var i = 2016; i<= currentYear; i++){
          this[i] = new Profstudymenu();
        }
        this[currentYear].hidden = false;
    }
}
