<div *ngIf="products != null" id="productMenu" class="mb-5 me-1 p-0">

  
  <div *ngFor="let p of products">
    <ng-container *ngIf="productService.activeCategory == p.category; then catActive; else catInactive"></ng-container>
    <ng-template #catActive>
        <a class="active h5 product product-category" [routerLink]="'gallery/'+p.category" style="text-decoration:none;">{{p.category}}</a>
    </ng-template>
    <ng-template #catInactive>
        <a class="h6 product product-category" [routerLink]="'gallery/'+p.category">{{p.category}}</a>
    </ng-template> 

        <div [id]="p.category" [ngbCollapse]="productService.activeCategory != p.category">
          <ng-container *ngFor="let prod of p.products">
          <ng-container *ngIf="prod.id == productService.activeProduct; then prodActive; else prodInactive"></ng-container>
          
            <ng-template #prodActive>
                <a class="active2 product product-item" routerLink="gallery/{{prod.category}}/detail/{{prod.id}}">{{prod.name}}</a>
            </ng-template>
            <ng-template #prodInactive>
                <a class="product product-item" routerLink="gallery/{{prod.category}}/detail/{{prod.id}}">{{prod.name}}</a>
            </ng-template>
          </ng-container>

        </div>
  </div>
    
</div>