import { Component, AfterViewChecked, Input } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { AuthorizedService } from '../../../../services/authorized/authorized.service';
import { MessageService } from '../../../../services/message/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],

})

export class CommentComponent implements AfterViewChecked{
  @Input() profstudyId : number;
  @Input() roundId : number;
  commentsForm : UntypedFormGroup;
  commentIndex : number = 0;
  scroll : boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private authorizedService:AuthorizedService, private messageService:MessageService, private activeModal:NgbActiveModal) {
    
    this.createForm();
  }
  createForm(){
    
    this.commentsForm = this.formBuilder.group({
      commentArray : this.formBuilder.array([])
    });

    //this.addAnotherComment();
    this.commentArray.push(this.newFormGroup());
  }
  get commentArray() : UntypedFormArray {
    return this.commentsForm.get('commentArray') as UntypedFormArray;
  }
  addAnotherComment(){

    this.commentArray.push(this.newFormGroup());
    this.scroll = true;
  }

  newFormGroup(){
    return this.formBuilder.group({
      page: '',
      paragraph: '',
      tableFigure: '',
      comment: ''
    });
  }

  close(){
    this.activeModal.close();
  }


  submitComments(){
    var sendData = new Object();
    sendData['profstudyId'] = this.profstudyId;
    sendData['roundId'] = this.roundId;
    sendData['comments'] = this.commentsForm.value.commentArray;
    var data = new Object();
    data['comments'] = sendData;
    this.authorizedService.postJSON('/refcig-services/authority/comments', sendData, this.successCallback, this.errorCallback, this);
  }

  successCallback(response){
    this.messageService.createMessage('success', 'Comments successfully submitted. Thank you for your feedback!');
    this.close();
  }

  errorCallback(error){
    this.close();
  }


  ngAfterViewChecked(){
    if(this.scroll){
      this.scroll = false;
      document.getElementById('commentBox').scrollBy(0,400);
    }

  }
}
