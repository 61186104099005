<div class="row content">
  <!--order is reversed in css for medium screens and larger-->
    <div class="col-md-9 order-md-2 ps-1 pe-2">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-3 order-md-1 sidenav ps-2 pe-0">
        <app-menu></app-menu>
      </div>
</div>

