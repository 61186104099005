import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizedService } from '../../../services/authorized/authorized.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { MessageService } from '../../../services/message/message.service';
import { OrdersService } from '../../../services/orders/orders.service';

@Component({
  selector: 'app-documentmanager',
  templateUrl: './documentmanager.component.html',
  styleUrls: ['./documentmanager.component.css']
})
export class OrderDocumentManager implements OnInit {
  fileData : any;
  fileValid : boolean;
  hasPO : boolean  = false;
  @Input() purchaseId: number;
  @Input() ctrpfiles: Array<any>;
  
  documentForm = new UntypedFormGroup({
    category : new UntypedFormControl('',[Validators.required]),
    description : new UntypedFormControl(),
    file : new UntypedFormControl()
  });
  isUploading : boolean = false;
  browserIsIE : boolean;

  constructor(private authorizedService : AuthorizedService, 
              private messageService : MessageService){
              //private orderService : OrdersService) {
    this.fileValid = false;
    this.ctrpfiles = new Array<any>();
    if(document['documentMode'] == null){
      this.browserIsIE = false;
    }
    else{
      this.browserIsIE = true;
    }
  }

  ngOnInit() {
  }

downloadDisclaimer(){
  this.authorizedService.getFile("/refcig-services/authority/"+this.authorizedService.userInfo.authorityInstitution[0].authority+"/downloadUnsignedDisclaimer/"+this.purchaseId);
}
submitDocument(){
  this.isUploading = true;//disable the submit button to prevent multiple uploads
  var formData = new FormData();
  formData.append('docCategory',this.documentForm.value.category);
  formData.append('file', this.fileData[0]);
  formData.append('docDescription',this.documentForm.value.description);
  var endpoint = "refcig-services/authority/"+this.authorizedService.userInfo.authorityInstitution[0].authority+"/purchase/"+this.purchaseId+"/uploadDocument";
  this.authorizedService.postFile(endpoint,formData,this.successPost, this.errorPost ,this);
}
onFileChange(event){
  this.fileData = event.target.files;
  this.fileValid = true;
  }
successPost(response){
  this.fileData = null;
  this.documentForm.reset();
  var messagebox = this.messageService.createMessage('success','File successfully uploaded and should appear in the table.');
  this.refreshFileList();
  this.isUploading = false;
  
}

errorPost(res){
  this.messageService.createMessage('error', res.message);
  this.isUploading = false;
}

deleteDocument(doc){
  var endpoint = "refcig-services/ctrpfile/delete";
  this.authorizedService.postJSON(endpoint,doc,this.refreshFileList,null,this);
  
  }
  refreshFileList(){
    if(this.purchaseId !=null){
      this.authorizedService.getOrderCtrpfiles(this.purchaseId);
    }
  }
  createLink(action,ctrpfileId){
    if(this.authorizedService.userInfo.username != null && this.purchaseId !=null){
      var URL = 'refcig-services/authority/' + this.authorizedService.userInfo.authorityInstitution[0].authority + '/download/purchase/'+ this.purchaseId + '/' + ctrpfileId;
      if(action == 'download'){
        if(this.browserIsIE == false){
          return URL;
        }
        else{
          this.authorizedService.getFile(URL);
        }
        
      }
      else{//deleting a file
        
      }
    }

  }
}