<div id="home" class="container-fluid">
  <div class="row mb-1">
    <app-news></app-news>
  </div>
  <div class="row">
    <div class="col-9">
      <h2>About CTRP</h2>
      
<p  >
    The Center for Tobacco Reference Products (CTRP) is part of the University of Kentucky, 
    College of Agriculture and has offered reference tobacco products as standards for 
    non-clinical investigations by tobacco manufacturers, contract and government laboratories, 
    and academic institutions since 1968.  The core mission of the CTRP is to design and supply 
    certified reference tobacco products and lead a proficiency testing program to better 
    serve the needs of the tobacco research industry. 
</p>
<p>
    Please note that all reference tobacco products provided by the CTRP are for research 
    purposes only and are not for human consumption. Please feel free to contact the 
    CTRP office if you have any questions at ctrp&#64;uky.edu or 859-257-2660.
</p>
      <div class="row">
        <div class="col-sm-6">
          <div class="card mb-1">
            <div class="card-header">
              Contact
            </div>
            <div class="card-body p1">
              <p class="card-text">
                Center for Tobacco Reference Products
                1401 University Drive <br/>
                Lexington, KY 40546-0236
              </p>
              <p class="card-text">
                Telephone: (859) 257-2660 <br/>Fax: (859) 257-6012 <br/>Email: <a href="mailto:ctrp&#64;uky.edu">ctrp&#64;uky.edu</a>
              </p>
              <p class="card-text">
                Please send us your feedback
              </p>
            </div>
          </div>

        </div>

        <div class="col-sm-6">
            <div class="card mb-1">
              <app-registration [hidden]="loggedIn"></app-registration>
              <app-profstudyschedule [hidden]="!loggedIn"></app-profstudyschedule>
            </div>
        </div>
      </div>

    </div>
    <div class='col-sm-3'>

      <div class="card mb-1">
        <div class="card-header">
          Additional Information
        </div>
        <ul class="list-group">
            <li class="list-group-item border-0"><a routerLink="/shipping">Shipping</a></li>
            <li class="list-group-item border-0"><a routerLink="/documents">Documentation</a></li>
            <li class="list-group-item border-0"><a href="/assets/pdf/webdocs/ProficiencyStudySchedule.pdf" target="_blank">Tentative Proficiency Study Schedule</a></li>
            <li class="list-group-item border-0"><a routerLink="/iso_accreditation">ISO Accreditation</a></li>
            <figure style = "margin-left:1em; margin-right:1em;">
              <a routerLink="/iso_accreditation"><img src="/assets/img/a2la.jpg" class="mt-3 col-12 imgPadding" maxHeight="140px"/></a><br/>
              <figcaption style="font-size:9px">[ISO/IEC 17043; A2LA Certificate Number 4034.01]</figcaption>
            </figure>
            
          </ul>
      </div>
      <div class="card mb-1">
        <div class="card-header">
          Site Updates
        </div>
        <div class="card-body">
          <p class="card-text">Please note that this site is regularly updated on Tuesdays at 4:30 EDT. During that time, the site may be briefly
            unavailable.
          </p>
        </div>
      </div>

    </div>
  </div>